import config from '@/config';
import { useMutation } from 'react-query';

export const useLogout = () => {
  const mutationFn = async ({
    ed4Token,
  }: {
    ed4Token?: string;
  }): Promise<void> => {
    return fetch(`${config.api.baseUrl}logout`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        ed4Token,
      }),
    }).then(async (response) => {
      return await response.json();
    });
  };

  const { mutateAsync: logout } = useMutation(mutationFn);

  return logout;
};
