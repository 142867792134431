import { signInMiddleware } from '@/contexts/Authentication/helpers';
import { authenticationReducer } from '@/contexts/Authentication/reducers';
import {
  AuthenticationAction,
  AuthenticationState,
} from '@/contexts/Authentication/types';
import useReducerWithLocalStorage from '../useReducerWithLocalStorage/useReducerWithLocalStorage';
import { useWithMiddleware } from '../useWithMiddleware/useWithMiddleware';

const useAuthenticationProviderSetup = () => {
  return useWithMiddleware(
    useReducerWithLocalStorage<AuthenticationState, AuthenticationAction>(
      authenticationReducer,
      {
        user: null,
        loginCallback: undefined,
        modalReason: 'default',
      },
      {
        key: 'enercity@auth',
      }
    ),
    [],
    [signInMiddleware]
  );
};

export default useAuthenticationProviderSetup;
