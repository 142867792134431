export enum BreakPoint {
  XSS = 375,
  XS = 576,
  SM = 768,
  MD = 992,
  LG = 1200,
  XL = 1400,
}

export const breakpoints = [
  BreakPoint.XSS,
  BreakPoint.XS,
  BreakPoint.SM,
  BreakPoint.MD,
  BreakPoint.LG,
];
