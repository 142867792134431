import clsx from 'clsx';
import React, { useEffect } from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Icon from '../Icon/Icon';
import styles from './FormField.module.scss';
import FormFieldLength from './FormFieldLength';
import { FormFieldType } from './consts';
import { FormFieldElement, FormFieldProps } from './interfaces';

const FormField = React.forwardRef(
  (
    {
      color = 'surrogate',
      type = FormFieldType.Text,
      pattern,
      label,
      errorMessage,
      invalid,
      maxLength,
      dontAllowMoreThenMaxLength,
      onChange,
      onFocus,
      onBlur,
      fullWidth,
      halfWidth,
      twoFifthsWidth,
      icon,
      isIconOutside = false,
      isBoxOnGradient = false,
      isIconHightlighted = false,
      errorMessageHasRelativePosition = false,
      iconSize = 'iconSize24',
      onClickIcon,
      className,
      suffix,
      suffixBoxText,
      suffixComponent,
      prefixBoxIcon,
      onSuffixBoxClick,
      withOutAutoFocus,
      isIconOutlined = true,
      min,
      max,
      labelId,
      ...props
    }: FormFieldProps,
    inputRef
  ): JSX.Element => {
    const internInputRef = React.useRef<HTMLTextAreaElement & HTMLInputElement>(
      null
    );
    const [isFocus, setIsFocus] = React.useState<boolean>(false);
    const isEmpty = !internInputRef?.current || !internInputRef.current.value;
    const showPlaceholder =
      type !== FormFieldType.Date &&
      ((props.readOnly && isEmpty && !props.value && !props.placeholder) ||
        (!isFocus && isEmpty && !props.value && !props.placeholder) ||
        (!isFocus && props.readOnly && !props.placeholder && !props.value));

    const handleOnChange = (
      $event: React.ChangeEvent<FormFieldElement>
    ): void => {
      if (
        dontAllowMoreThenMaxLength &&
        maxLength &&
        $event.target.value.length >= maxLength + 1
      ) {
        return;
      }

      onChange && onChange($event);
    };

    const handleOnFocus = (
      $event: React.FocusEvent<FormFieldElement>
    ): void => {
      setIsFocus(true);
      onFocus && onFocus($event);
    };

    const handleOnBlur = ($event: React.FocusEvent<FormFieldElement>): void => {
      setIsFocus(false);
      onBlur && onBlur($event);
    };

    useEffect(() => {
      if (isFocus && props.disabled) {
        setIsFocus(false);
      }
    }, [isFocus, props.disabled]);

    const Tag = type === FormFieldType.Textarea ? 'textarea' : 'input';

    const handleOnClickIcon = () => {
      if (onClickIcon) {
        onClickIcon();

        if (!withOutAutoFocus) {
          internInputRef.current && internInputRef.current.focus();
        }
      }
    };

    const iconEl = (
      <span
        className={clsx(styles.icon, {
          [styles.iconHighlighted]: isIconHightlighted,
          [styles.withGradient]: isBoxOnGradient,
          [styles.outside]: isIconOutside,
        })}
        onClick={handleOnClickIcon}
      >
        {icon && (
          <Icon variant={icon} size={iconSize} isOutlined={isIconOutlined} />
        )}
      </span>
    );

    React.useEffect(() => {
      typeof inputRef === 'function' && inputRef(internInputRef.current);
    }, [inputRef]);

    return (
      <div
        className={clsx(styles.wrapper, {
          [styles.half]: halfWidth,
          [styles.full]: fullWidth,
          [styles.twoFifths]: twoFifthsWidth,
        })}
      >
        <div
          className={clsx(styles.container, styles[color], className, {
            [styles.iconOutside]: isIconOutside,
            [styles.focus]: isFocus,
            [styles.invalid]: !!errorMessage || invalid,
            [styles.withGradient]: isBoxOnGradient,
          })}
          data-formfield
        >
          {!icon && prefixBoxIcon && (
            <div
              className={clsx(styles.prefixBox, {
                [styles.focus]: isFocus,
              })}
            >
              <Icon variant={prefixBoxIcon} size={iconSize} />
            </div>
          )}
          <label
            className={clsx(styles.formField, {
              [styles.withSuffixBox]: suffixBoxText,
              [styles.withGradient]: isBoxOnGradient,
              [styles.focus]: isFocus,
              [styles.disabled]: props.disabled,
              [styles.invalid]: !!errorMessage || invalid,
              [styles.withIcon]: icon,
              [styles.formFieldTextarea]: type === FormFieldType.Textarea,
            })}
            id={labelId}
          >
            {!!label && (
              <span
                className={clsx(styles.label, {
                  [styles.withGradient]: isBoxOnGradient,
                  [styles.placeholder]: showPlaceholder,
                  [styles.withSuffixComponent]: suffixComponent,
                })}
              >
                {label}
                {suffix && (
                  <span className={styles.labelSuffix}> {suffix}</span>
                )}
                {suffixComponent && (
                  <span className={styles.labelSuffixComponent}>
                    {suffixComponent}
                  </span>
                )}
              </span>
            )}

            {maxLength && type !== FormFieldType.Textarea && (
              <FormFieldLength
                show={isFocus}
                value={props.value}
                maxLength={maxLength}
                isOnGradient={isBoxOnGradient}
              />
            )}
            <Tag
              type={type}
              pattern={pattern}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              rows={type === FormFieldType.Textarea ? 8 : undefined}
              ref={internInputRef}
              min={min}
              max={max}
              {...props}
            />
          </label>
          {iconEl}
          {!icon && suffixBoxText && (
            <div
              className={clsx(styles.suffixBox, {
                [styles.focus]: isFocus,
              })}
              onClick={onSuffixBoxClick}
            >
              {suffixBoxText}
            </div>
          )}
        </div>
        {errorMessage && (
          <ErrorMessage
            hasRelativePosition={errorMessageHasRelativePosition}
            withWhiteColor={isBoxOnGradient}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </div>
    );
  }
);

FormField.displayName = 'FormField';

export default FormField;
