import useLoadMarkerIo from '@/hooks/useLoadMarkerIo/useLoadMarkerIo';
import React from 'react';
import { MarkerIoContextValue, MarkerIoProviderProps } from './types';

const initialValue: MarkerIoContextValue = {
  isMarkerIoLoaded: false,
  markerIo: null,
  loadWidget: () => void 0,
  unloadWidget: () => void 0,
};

const MarkerIoContext = React.createContext<MarkerIoContextValue>(initialValue);

export const MarkerIoProvider = ({ children }: MarkerIoProviderProps) => {
  const { isLoaded, loadWidget, unloadWidget, widget } = useLoadMarkerIo();

  return (
    <MarkerIoContext.Provider
      value={{
        markerIo: widget,
        isMarkerIoLoaded: isLoaded,
        loadWidget,
        unloadWidget,
      }}
    >
      {children}
    </MarkerIoContext.Provider>
  );
};

export default MarkerIoContext;
