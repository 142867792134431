import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { isAWSStage } from '@uikit/helpers/isAWSStage';
import type { VideoInputDataSizes, VideoSource } from './types';

const FRONTEND_DEV_BASE_URL = 'https://aws-dev.enercity.de';
const STAGE = process.env.STAGE;
const baseUrl = getBaseUrl();

export const getVideoBasePath = (stage = STAGE) => {
  return isAWSStage(stage) ? baseUrl : FRONTEND_DEV_BASE_URL;
};

export const createVideoUrl = (path: string, stage?: string) => {
  return getVideoBasePath(stage) + path;
};

export const createVideoSources = (
  sizes: VideoInputDataSizes
): VideoSource[] => {
  const sources = Object.entries(sizes).map(([key, value]) => {
    const extension = value.split('.').pop() ?? 'mp4';
    const [format, resolution] = key.includes('_')
      ? key.split('_')
      : [extension, key];

    return {
      format,
      size: parseFloat(resolution),
      src: createVideoUrl(value, STAGE),
    };
  });

  sources.sort((a, b) => a.size - b.size);

  return sources;
};

export const findSize = (size: number, sizes: number[]) => {
  return sizes
    .toSorted((a, b) => a - b)
    .find((value, index) => size <= value || index === sizes.length - 1);
};
