import config from '../../config';

export enum UseScriptConfigVariant {
  Aklamio = 'aklamio',
  CMPVendorList = 'cmpVendorList',
  CMPCookieInfo = 'cmpCookieInfo',
  EPilot = 'ePilot',
}

export const useScriptConfigs = {
  [UseScriptConfigVariant.Aklamio]: {
    src: `https://api.aklamio.com/assets/embed/1.latest/embed.min.js`,
  },
  [UseScriptConfigVariant.CMPVendorList]: {
    src:
      config.consentManagerId &&
      `https://delivery.consentmanager.net/delivery/vendorlist.php?cdid=${config.consentManagerId}&l=de`,
  },
  [UseScriptConfigVariant.CMPCookieInfo]: {
    src:
      config.consentManagerId &&
      `https://delivery.consentmanager.net/delivery/cookieinfo.php?cdid=${config.consentManagerId}&l=de`,
  },
  [UseScriptConfigVariant.EPilot]: {
    src: `https://embed.journey.epilot.io/bundle.js`,
  },
};
