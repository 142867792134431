import clsx from 'clsx';
import React from 'react';
import Button from '../Button/Button';
import { ButtonSize, ButtonType } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import styles from './ButtonSubmit.module.scss';
import { ButtonSubmitState, DEFAULT_STATE_MESSAGES } from './consts';
import type {
  ButtonSubmitColorByType,
  ButtonSubmitProps,
  ButtonSubmitStateType,
} from './interfaces';

const styleMapper = (
  state: ButtonSubmitStateType,
  buttonColor?: ButtonSubmitColorByType
) => {
  if (buttonColor && state) {
    if (buttonColor[state]) {
      return styles[buttonColor[state]!];
    }
  }
};

const ButtonSubmit = ({
  children,
  state = ButtonSubmitState.Initial,
  buttonColor,
  stateMessages,
  inactive,
  gradient,
  noCenter,
  onClick,
  type = ButtonType.Submit,
  className,
  buttonRef,
  align,
  clickContext,
  ...props
}: ButtonSubmitProps) => {
  const messages = { ...DEFAULT_STATE_MESSAGES, ...stateMessages };

  return (
    <Button
      size={ButtonSize.Medium}
      inactive={inactive}
      className={clsx(
        styles.default,
        styles[state],
        styleMapper(state, buttonColor),
        className,
        {
          [styles.inactive]: inactive,
          [styles.gradient]: gradient,
          [styles.noCenter]: noCenter,
          [styles[align!]]: align,
        }
      )}
      {...props}
      actionType={ClickableAction.None}
      onClick={onClick}
      noClickable
      type={type}
      ref={buttonRef}
      clickContext={clickContext}
    >
      <span
        className={clsx(styles.stateMessageInitial, {
          [styles.hasIcon]: gradient,
        })}
      >
        {children}
      </span>
      <span className={styles.stateMessagePending}>{messages.pending}</span>
      <span className={clsx(styles.stateMessage, styles.stateMessageFail)}>
        {messages.fail}
      </span>
      <span className={clsx(styles.stateMessage, styles.stateMessageSuccess)}>
        {messages.success}
      </span>
      <span className={clsx(styles.stateMessage, styles.stateMessageInfo)}>
        {messages.info}
      </span>
    </Button>
  );
};

export default ButtonSubmit;
