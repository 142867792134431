import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { firstIfArray } from '@uikit/utils/firstIfArray';
import React, { useState } from 'react';
import type { ModalContextValue } from './types';

const initialValue: ModalContextValue = {
  currentModalContextId: '',
  isVisibleRearLayer: false,
  showModal: () => {},
  showRearLayer: () => {},
  hideRearLayer: () => {},
  closeModal: () => {},
};

const ModalContext = React.createContext<ModalContextValue>(initialValue);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    params: { 'show-modal': modalId },
    set: setQueryParams,
  } = useQueryParams();

  const [isVisibleRearLayer, setVisibleRearLayer] = useState<boolean>(false);
  const { Provider } = ModalContext;
  const value: ModalContextValue = {
    currentModalContextId: firstIfArray(modalId) ?? '',
    isVisibleRearLayer,
    showModal: (modalId: string) => {
      if (!modalId) {
        setQueryParams({ 'show-modal': undefined });
        return;
      }

      setQueryParams({ 'show-modal': modalId });
    },
    showRearLayer: () => setVisibleRearLayer(() => true),
    hideRearLayer: () => setVisibleRearLayer(() => false),
    closeModal: () => setQueryParams({ 'show-modal': undefined }),
  };

  return <Provider value={value}>{children}</Provider>;
};

export default ModalContext;
