import useAuthentication from '@/hooks/useAuthentication/useAuthentication';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useForm from '@/hooks/useForm/useForm';
import useMeasure from '@/hooks/useMeasure/useMeasure';
import { animated, useSpring } from '@react-spring/web';
import ButtonLink from '@uikit/components/ButtonLink/ButtonLink';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import Clickable from '@uikit/components/Clickable/Clickable';
import Divider from '@uikit/components/Divider/Divider';
import ErrorMessageBanner from '@uikit/components/ErrorMessageBanner/ErrorMessageBanner';
import Headline from '@uikit/components/Headline/Headline';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import RichText from '@uikit/components/RichText/RichText';
import Stack from '@uikit/components/Stack/Stack';
import WarningBanner from '@uikit/components/WarningBanner/WarningBanner';
import { Align } from '@uikit/types';
import React from 'react';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonWidth,
} from '../../../Button/consts';
import { ClickableAction } from '../../../Clickable/consts';
import FormField from '../../../FormField/FormField';
import { FormFieldType } from '../../../FormField/consts';
import FormFieldPassword from '../../../FormFieldPassword/FormFieldPassword';
import { AuthenticationModalReasonsText } from '../../consts';
import { globalAuthenticationLoginSchema } from '../../schema';
import styles from './LoginForm.module.scss';
import type { GlobalAuthenticationLoginFormProps } from './interfaces';

const GlobalAuthenticationLoginForm = ({
  callbackOnSubmit,
  errorMessage,
}: GlobalAuthenticationLoginFormProps) => {
  const [loginStep, setLoginStep] = React.useState<'email' | 'password'>(
    'email'
  );
  const isStepPassword = loginStep === 'password';
  const heightRef = React.useRef<HTMLDivElement>();
  const { height } = useMeasure(heightRef);
  const { modalReason } = useAuthentication();
  const [submitState, setSubmitState] = React.useState<ButtonSubmitState>(
    ButtonSubmitState.Initial
  );

  const handleFormSubmit = async (state: UseFormState) => {
    if (submitState === ButtonSubmitState.Initial && isStepPassword) {
      setSubmitState(() => ButtonSubmitState.Pending);

      try {
        await callbackOnSubmit(state);

        setSubmitState(() => ButtonSubmitState.Success);
      } catch (error) {
        setSubmitState(() => ButtonSubmitState.Fail);

        setTimeout(() => {
          setSubmitState(() => ButtonSubmitState.Initial);
        }, 2000);
      }
    }
  };

  const {
    formState,
    getErrorByFieldName,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isFormValid,
    isFieldValid,
  } = useForm(handleFormSubmit, globalAuthenticationLoginSchema, {
    email: '',
    password: '',
  });

  const passwordAnimationProps = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: `translateY(-1rem)`,
      width: '100%',
    },
    to: {
      height: isStepPassword ? height : 0,
      opacity: isStepPassword ? 1 : 0,
      transform: isStepPassword ? `translateY(0)` : `translateY(-1rem)`,
      width: '100%',
      pointerEvents: isStepPassword ? `all` : `none`,
    },
  });

  const handleEmailSubmit = ($event: React.FormEvent<HTMLFormElement>) => {
    $event.preventDefault();

    if (isFieldValid?.('email', true) && loginStep === 'email') {
      setLoginStep('password');
      setFieldValue('email', formState['email'].trim());
      return;
    }

    handleSubmit($event);
  };

  return (
    <Stack gap={{ initial: 1.5, sm: 2 }} alignItems="center">
      <Headline
        headlineColor={HeadlineColor.Dark}
        level={2}
        subText={AuthenticationModalReasonsText[modalReason].subText}
      >
        {AuthenticationModalReasonsText[modalReason].headline}
      </Headline>

      {errorMessage && <ErrorMessageBanner errorMessage={errorMessage} />}
      {!errorMessage && (
        <WarningBanner>
          Derzeit kann es vereinzelt zu Problemen bei der Anmeldung zur
          Vorteilswelt kommen. Wir bitten um Ihre Geduld. Falls Sie betroffen
          sind und keine Gutscheincodes abrufen können, wenden Sie sich gerne an{' '}
          <a href="mailto:vorteile@enercity.de?subject=Probleme%20bei%20der%20Anmeldung">
            vorteile@enercity.de
          </a>
          .
        </WarningBanner>
      )}

      <form onSubmit={handleEmailSubmit} className={styles.form}>
        <Stack gap={{ initial: 1.5, sm: 2 }} alignItems="center">
          <Stack gap={1} alignItems="center">
            <Stack gap={0.75} alignItems="center">
              <FormField
                label="E-Mail oder Benutzername"
                name={'email'}
                fullWidth
                autoComplete="email"
                value={formState.email}
                type={FormFieldType.Text}
                errorMessage={getErrorByFieldName?.(`email`)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isStepPassword}
                icon={isStepPassword ? 'user/pen' : undefined}
                iconSize={isStepPassword ? 'iconSize24' : undefined}
                onClickIcon={() => {
                  if (isStepPassword) {
                    setLoginStep('email');
                    setFieldValue('password', '');
                  }
                }}
                errorMessageHasRelativePosition
              />
              <animated.div style={passwordAnimationProps}>
                <div ref={heightRef as React.MutableRefObject<HTMLDivElement>}>
                  {isStepPassword && (
                    <FormFieldPassword
                      label="Passwort"
                      autoComplete="current-password"
                      name={'password'}
                      value={formState['password'] || ''}
                      errorMessage={getErrorByFieldName?.(`password`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoFocus
                      fullWidth
                      errorMessageHasRelativePosition
                    />
                  )}
                </div>
              </animated.div>
            </Stack>

            <Clickable
              className={styles.link}
              actionType={ClickableAction.Linkextern}
              linkextern={'https://www.enercity.de/faq/enercity-portal'}
            >
              FAQ: Ich kann mich nicht einloggen
            </Clickable>
          </Stack>

          <ButtonSubmit
            color={ButtonColor.Primary}
            size={ButtonSize.Medium}
            width={ButtonWidth.Auto}
            type={ButtonType.Submit}
            align={ButtonAlign.Center}
            inactive={isStepPassword ? !isFormValid : !isFieldValid?.('email')}
            state={submitState}
            stateMessages={{
              pending: 'Anmelden...',
              success: 'Sie wurden angemeldet',
              fail: 'Ein Fehler ist aufgetreten',
            }}
          >
            {isStepPassword ? 'Anmelden' : `Weiter`}
          </ButtonSubmit>
        </Stack>
      </form>
      <Divider dividerText={'Oder'} />

      <Stack gap={1}>
        <RichText align={Align.Center}>
          <span>
            Sie sind Kunde, haben aber noch keinen Zugang zu Ihrem enercity
            Portal?
          </span>
        </RichText>

        <ButtonLink
          actionType={ClickableAction.Linkextern}
          linkextern={'https://www.enercity.de/registrierung'}
          align={ButtonAlign.Center}
        >
          Jetzt registrieren
        </ButtonLink>
      </Stack>
    </Stack>
  );
};

export default GlobalAuthenticationLoginForm;
