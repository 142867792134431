import { logger } from '@/helpers/logger';
import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useEffect } from 'react';
import type { AklamioHelperProps } from './types';

const AID_STARTS_WITH = {
  solar: '6d33ad9a60b98bb63546936bbddfd0af',
  'power-gas': 'e9185cf41d57d97b5752f30ed833fc2a',
};

const AklamioHelper = ({}: AklamioHelperProps) => {
  const { params } = useQueryParams();

  const aid = params.aid;

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !aid ||
      typeof aid !== 'string' ||
      aid.trim() === ''
    )
      return;

    const date = new Date();
    const futureDate = date.setDate(date.getDate() + 30);

    let oldAidCookieValue = {};
    const cookieString = document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith('aklamio.recommendation'));

    if (cookieString) {
      try {
        oldAidCookieValue = JSON.parse(cookieString.split('=')[1]);
      } catch (error) {
        logger.error('Invalid cookie format', error);
      }
    }

    const currentAidType = Object.keys(AID_STARTS_WITH).find((key) => {
      if (aid.includes(AID_STARTS_WITH[key as keyof typeof AID_STARTS_WITH])) {
        return key;
      }
      return undefined;
    }) as keyof typeof AID_STARTS_WITH | undefined;

    if (!currentAidType) {
      logger.error('no type found for aid', aid);
      return;
    }

    const newAidCookieValue = {
      ...oldAidCookieValue,
      [currentAidType]: aid,
    };

    document.cookie = `aklamio.recommendation=${JSON.stringify(
      newAidCookieValue
    )}; expires=${new Date(
      futureDate
    ).toUTCString()}; domain=.enercity.de; path=/;`;
  }, [aid]);

  return null;
};

export default AklamioHelper;
