import clsx from 'clsx';
import Icon from '../Icon/Icon';
import styles from './WarningBanner.module.scss';
import type { LoginWarningBannerProps } from './types';

const WarningBanner = ({ className, children }: LoginWarningBannerProps) => {
  return (
    <div className={clsx(className, styles.warningBanner)}>
      <Icon
        variant="user/information"
        className={styles.warningIcon}
        size="iconSize32"
      />
      <span>{children}</span>
    </div>
  );
};

export default WarningBanner;
