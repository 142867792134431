import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import { BreakpointAspectRatioConfigs } from './image-transformer/types';

export const CLASSIC_BREAKPOINT_ASPECT_RATIO_CONFIGS: BreakpointAspectRatioConfigs =
  [
    {
      breakpoint: BreakPoint.XSS,
      aspectRatio: '4:3',
    },
    {
      breakpoint: BreakPoint.XS,
      aspectRatio: '4:3',
    },
    {
      breakpoint: BreakPoint.SM,
      aspectRatio: '4:3',
    },
    {
      breakpoint: BreakPoint.MD,
      aspectRatio: '4:3',
    },
    {
      breakpoint: BreakPoint.LG,
      aspectRatio: '4:3',
    },
    {
      breakpoint: BreakPoint.XL,
      aspectRatio: '4:3',
    },
  ];

export const CINEMASCOPE_BREAKPOINT_ASPECT_RATIO_CONFIGS: BreakpointAspectRatioConfigs =
  [
    {
      breakpoint: BreakPoint.XSS,
      aspectRatio: '21:9',
    },
    {
      breakpoint: BreakPoint.XS,
      aspectRatio: '21:9',
    },
    {
      breakpoint: BreakPoint.SM,
      aspectRatio: '21:9',
    },
    {
      breakpoint: BreakPoint.MD,
      aspectRatio: '21:9',
    },
    {
      breakpoint: BreakPoint.LG,
      aspectRatio: '21:9',
    },
    {
      breakpoint: BreakPoint.XL,
      aspectRatio: '21:9',
    },
  ];

export const WIDESCREEN_BREAKPOINT_ASPECT_RATIO_CONFIGS: BreakpointAspectRatioConfigs =
  [
    {
      breakpoint: BreakPoint.XSS,
      aspectRatio: '16:9',
    },
    {
      breakpoint: BreakPoint.XS,
      aspectRatio: '16:9',
    },
    {
      breakpoint: BreakPoint.SM,
      aspectRatio: '16:9',
    },

    {
      breakpoint: BreakPoint.MD,
      aspectRatio: '16:9',
    },

    {
      breakpoint: BreakPoint.LG,
      aspectRatio: '16:9',
    },

    {
      breakpoint: BreakPoint.XL,
      aspectRatio: '16:9',
    },
  ];

export const DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS: BreakpointAspectRatioConfigs =
  [
    {
      breakpoint: BreakPoint.XS,
    },
    {
      breakpoint: BreakPoint.SM,
    },
    {
      breakpoint: BreakPoint.MD,
    },
    {
      breakpoint: BreakPoint.LG,
    },
    {
      breakpoint: BreakPoint.XL,
    },
  ];
