import { ClickableAction } from '../Clickable/consts';
import type { IconVariants } from '@uikit/components/Icon/interfaces';

export const actionToIconMapper: { [key in ClickableAction]?: IconVariants } = {
  [ClickableAction.Phone]: 'user/phone',
  [ClickableAction.Asset]: 'action/download',
  [ClickableAction.Linkextern]: 'action/arrow-right',
  [ClickableAction.MailTo]: 'user/mail',
};

export enum ButtonLinkStyleVariant {
  Button = 'button',
  TextLink = 'textLink',
}
