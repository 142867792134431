import { getJson } from '@/helpers/ajax';
import { useQuery } from 'react-query';
import { getVideoBasePath } from '../helpers';
import { VideoDataInput } from '../types';

const basePath = getVideoBasePath();

const fetchVideos = async (videoName?: string) => {
  if (!videoName) {
    throw new Error('no video name');
  }

  return await getJson<VideoDataInput>(
    `${basePath}/cdn/videos/${videoName}/${videoName}.json`
  );
};

export const useVideoData = (videoName?: string) => {
  return useQuery(
    ['videoData', videoName],
    async () => await fetchVideos(videoName),
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 5_000,
      staleTime: Infinity,
    }
  );
};
