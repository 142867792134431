import { ICONS_BASE_URL, MEDIUM_ICON_MAX_SIZE } from './config';
import type { IconSize } from './interfaces';

export const withIconBasePath = (path: string) => `${ICONS_BASE_URL}${path}`;

const iconSizeToNumber = (size: IconSize) => +size.replace(/[^\d]/g, '');

const getIconFileUrl = (
  variant: string,
  size: IconSize,
  isOutlined: boolean
): string => {
  const splittedNames = variant.split('/');
  const [categoryName, iconName] =
    splittedNames.length === 2 ? splittedNames : [undefined, variant];

  if (categoryName && categoryName !== 'user') {
    return withIconBasePath(`/${variant}.svg`);
  }

  const subDir = isOutlined
    ? 'outlined-' +
      (iconSizeToNumber(size) > iconSizeToNumber(MEDIUM_ICON_MAX_SIZE)
        ? 'large'
        : 'medium')
    : 'solid';

  return withIconBasePath(`/${subDir}/${iconName}.svg`);
};

export default getIconFileUrl;
