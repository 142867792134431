import { AuthenticationContext } from '@/contexts/Authentication/Authentication.context';
import React from 'react';

const useAuthentication = () => {
  const { state, ...restProps } = React.useContext(AuthenticationContext);

  if (state === undefined) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationContext provider'
    );
  }

  const isAuthenticated = !!(
    (state.user?.ed4 || state.user?.isu) &&
    state.user?.email
  );

  return {
    ...state,
    ...restProps,
    isAuthenticated,
  };
};

export default useAuthentication;
