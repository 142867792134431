import { ClickableProps } from '../components/Clickable/interfaces';

const CLICKABLE_PROPS_KEYS = [
  'actionType',
  'anchor',
  'aria-controls',
  'aria-expanded',
  'asButton',
  'asDiv',
  'asset',
  'calendar',
  'clickContext',
  'customDataLayer',
  'domain',
  'download',
  'email',
  'emailBCC',
  'emailCC',
  'emailSubject',
  'emailText',
  'iframelink',
  'journeyId',
  'lightbox',
  'linkextern',
  'linkintern',
  'modallink',
  'nofollow',
  'onClick',
  'params',
  'phone',
  'target',
  'topic',
  'videoName',
  'videolink',
];

export const extractClickableProps = <
  T extends ClickableProps | Omit<ClickableProps, 'children'>,
>(
  props: T
) => {
  return Object.keys(props).reduce((reducedProps, key) => {
    if (!CLICKABLE_PROPS_KEYS.includes(key)) {
      return reducedProps;
    }

    return { ...reducedProps, [key]: props[key as keyof T] };
  }, {} as ClickableProps);
};
