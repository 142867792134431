import clsx from 'clsx';
import React, { useEffect } from 'react';
import Icon from '../Icon/Icon';
import styles from './ErrorMessageBanner.module.scss';
import type { ErrorMessageBannerProps } from './interfaces';
import useDataLayer from '../../../hooks/useDataLayer/useDataLayer';

const ErrorMessageBanner = ({
  errorMessage,
  className,
}: ErrorMessageBannerProps) => {
  const { pushDataLayerEvent } = useDataLayer();

  useEffect(() => {
    pushDataLayerEvent({
      event: 'Fehlermeldung',
      errorMsgContent: errorMessage,
    });
  }, [pushDataLayerEvent, errorMessage]);

  return (
    <div className={clsx(styles.main, className)}>
      <Icon variant={'user/information'} size="iconSize32" />
      <div
        dangerouslySetInnerHTML={{ __html: errorMessage }}
        className={styles.text}
      />
    </div>
  );
};

export default ErrorMessageBanner;
