import { useEffect, useState } from 'react';
import { BreakPoint, breakpoints } from './consts';

const reversedBreakpoints = [...breakpoints].reverse();

const breakpointGetters = {
  from: (windowWidth: number): BreakPoint => {
    const breakpointIndex = reversedBreakpoints.findIndex(
      (breakpoint) => windowWidth >= breakpoint
    );

    return breakpointIndex === -1
      ? BreakPoint.XSS
      : breakpoints[breakpointIndex];
  },
  to: (windowWidth: number): BreakPoint => {
    const breakpointIndex = breakpoints.findIndex(
      (breakpoint) => windowWidth <= breakpoint
    );

    return breakpointIndex === -1
      ? BreakPoint.XL
      : breakpoints[breakpointIndex];
  },
} as const;

const useBreakPoint = (
  getterName: keyof typeof breakpointGetters = 'from'
): BreakPoint => {
  const [breakpoint, setBreakpoint] = useState(BreakPoint.SM);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const resizeListener = () => {
      setBreakpoint(() => breakpointGetters[getterName](window.innerWidth));
    };

    resizeListener();

    window.addEventListener('resize', resizeListener, { passive: true });

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [breakpoint, getterName]);

  return breakpoint;
};

export default useBreakPoint;
