import clsx from 'clsx';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { memo } from 'react';
import PictureSource from './PictureSource';
import {
  calculateHeightByAspectRatio,
  createAbsolutePath,
  createImageTransformerURL,
  createPlaceholderURL,
  sortByBreakpoint,
} from './helpers';
import type {
  ImageTransformerParams,
  ImageTransformerPictureProps,
} from './types';

const MIN_PICTURE_WIDTH = 375;

const ImageTransformerPicture = ({
  breakpointAspectRatioConfigs,
  imgProps,
  path,
  ratioVariants,
  nativeLazy,
  defaultWidth = MIN_PICTURE_WIDTH,
  defaultAspectRatio,
  className,
  crop = true,
}: ImageTransformerPictureProps) => {
  const sortedConfigs =
    breakpointAspectRatioConfigs &&
    sortByBreakpoint(breakpointAspectRatioConfigs, 'asc');

  const [firstConfig] = sortedConfigs ?? [];

  const shouldCalculateHeight =
    !ratioVariants &&
    ((firstConfig?.aspectRatio && firstConfig?.width) ||
      (defaultAspectRatio && defaultWidth));

  const defaultParams: ImageTransformerParams = {
    ...(shouldCalculateHeight
      ? {
          h: calculateHeightByAspectRatio(
            firstConfig.width ?? defaultWidth,
            firstConfig.aspectRatio ?? defaultAspectRatio
          ),
        }
      : {}),
    ...(crop ? { resize: 'cover' } : {}),
    w: defaultWidth,
  };
  const placeholderImageUrl = createPlaceholderURL(path, defaultParams);
  const fallbackImageUrl = createImageTransformerURL(path, defaultParams);

  return (
    <picture className={className}>
      {sortedConfigs?.map(
        ({ breakpoint, aspectRatio, width }, index, configs) => {
          const hasWidestBreakpoint = index === configs.length - 1;
          return (
            <PictureSource
              key={breakpoint}
              aspectRatio={aspectRatio}
              width={width}
              breakpoint={breakpoint}
              path={path}
              defaultParams={{ ...defaultParams, dpr: 2 }}
              defaultWidth={defaultWidth}
              defaultAspectRatio={defaultAspectRatio}
              ratioVariants={ratioVariants}
              hasMedia={!hasWidestBreakpoint}
            />
          );
        }
      )}

      <img
        src={placeholderImageUrl}
        data-src={fallbackImageUrl}
        {...imgProps}
        {...(nativeLazy ? { loading: 'lazy', decoding: 'async' } : {})}
        className={clsx('lazyload', imgProps?.className)}
        alt={imgProps?.alt}
      />
    </picture>
  );
};

const PictureMagnoliaWrapper = (props: ImageTransformerPictureProps) => {
  if (typeof window !== 'undefined' && window.USE_MAGNOLIA_RENDERER) {
    const content = (
      <img
        src={createAbsolutePath(props.path)}
        {...props.imgProps}
        alt={props.imgProps?.alt}
      />
    );

    if (props.className) {
      return <picture className={props.className}>{content}</picture>;
    }

    return content;
  }

  return <ImageTransformerPicture {...props} />;
};

export default memo(PictureMagnoliaWrapper);
