type SeparatorCase = ' ' | '-' | '_';

type CamelCase<T extends string> = T extends `${SeparatorCase}${infer Suffix}`
  ? CamelCase<Suffix>
  : T extends `${infer Prefix}${SeparatorCase}`
  ? CamelCase<Prefix>
  : T extends `${infer Prefix}${SeparatorCase}${infer Suffix}`
  ? CamelCase<`${Prefix}${Capitalize<Suffix>}`>
  : T;

type KebabCase<
  S extends string,
  A extends string = ''
> = S extends `${infer F}${infer R}`
  ? KebabCase<
      R,
      `${A}${'' extends A
        ? ''
        : F extends Lowercase<F>
        ? ''
        : '-'}${Lowercase<F>}`
    >
  : A;

type PascalCase<Value extends string> = CamelCase<Value> extends string
  ? Capitalize<CamelCase<Value>>
  : CamelCase<Value>;

export const toCamelCase = <T extends string>(value: T) =>
  value.replace(/(([-_])[a-z])/g, (group: string) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  ) as CamelCase<T>;

export const toKebabCase = <T extends string>(value: T): KebabCase<T> =>
  value.replace(
    /[A-Z]+(?:[a-z])|[A-Z]/g,
    (s, args) => (args ? '-' : '') + s.toLowerCase()
  ) as KebabCase<T>;

export const toPascalCase = <T extends string>(value: T) =>
  value.replace(/((^|[-_])[a-z])/g, (group: string) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  ) as PascalCase<T>;

export const capitalize = <T extends string>(value: T) => {
  return (value.charAt(0).toUpperCase() + value.slice(1)) as Capitalize<T>;
};
