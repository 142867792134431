import { useResponsiveVideo } from './hooks/useResponsiveVideo';
import type { VideoPlayerProps } from './types';

const VideoPlayer = ({ videoData, ...videoProps }: VideoPlayerProps) => {
  const { videoRef, sources, selectedSize, isLoaded } = useResponsiveVideo(
    videoData?.sizes
  );

  return (
    <video
      ref={videoRef}
      key={selectedSize}
      controlsList="nodownload"
      {...videoProps}
      data-loaded={isLoaded}
    >
      {sources.map(({ format, src }) => (
        <source key={format + src} src={src} type={`video/${format}`} />
      ))}
      Ihr Browser unterstützt das Video-Tag nicht / Your browser does not
      support the video tag.
    </video>
  );
};

export default VideoPlayer;
