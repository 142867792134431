import { createContext, useState } from 'react';
import { CommandMenuContextValue, CommandMenuProviderProps } from './types';

const initialValue: CommandMenuContextValue = {
  getData: () => void 0,
  pageData: void 0,
  registerItem: () => void 0,
  setPageData: () => void 0,
  unregisterItem: () => void 0,
};

const CommandMenuContext = createContext<CommandMenuContextValue>(initialValue);

export const CommandMenuProvider = ({ children }: CommandMenuProviderProps) => {
  const [itemData, setItemData] = useState<Record<string, any>>({});
  const [pageData, setPageData] = useState<any>();

  const getData = (value: string) => {
    return itemData[value];
  };

  const registerItem = (value: string, data: any) => {
    setItemData((d) => ({ ...d, [value]: data }));
  };

  const unregisterItem = (value: string) => {
    setItemData(({ [value]: _, ...d }) => d);
  };

  const value: CommandMenuContextValue = {
    getData,
    pageData,
    registerItem,
    setPageData,
    unregisterItem,
  };

  return (
    <CommandMenuContext.Provider value={value}>
      {children}
    </CommandMenuContext.Provider>
  );
};

export default CommandMenuContext;
