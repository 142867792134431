import { logger } from '@/helpers/logger';
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import useSessionStorage from '../useSessionStorage/useSessionStorage';
import { fileToBase64 } from './fileToBase64';
import {
  FormFile,
  FormValidations,
  UseForm,
  UseFormErrorState,
  UseFormState,
} from './interfaces';
import { ErrorMessage, FieldValue, FormElement } from './types';

// FIXME: it is workaround, Yup.isSchema doesn't work properly
function isYupSchema(value: any): value is Yup.AnySchema {
  return Boolean(value && '__isYupSchema__' in value && value.__isYupSchema__);
}

const useForm = <Schema extends Yup.AnyObject>(
  callbackOnSubmit: (submittedFormState: UseFormState) => void,
  validations: FormValidations | Schema,
  initialState: UseFormState = {},
  stogrageKey?: string
): UseForm => {
  const [storageValue, setStorageValue] = useSessionStorage(
    stogrageKey ? `useForm@${stogrageKey}` : '',
    initialState
  );

  const [formState, setFormState] = useState<UseFormState>(
    stogrageKey ? storageValue : initialState
  );
  const [errorMessages, setErrorMessages] = useState<UseFormErrorState>({});

  const validate = (
    name: string,
    value: FieldValue
  ): void | Yup.ValidationError => {
    if (typeof validations === 'undefined') {
      return void 0;
    }

    if (isYupSchema(validations)) {
      validations.validateSyncAt(name, { ...formState, [name]: value });
    } else {
      const validation = validations[name];
      isYupSchema(validation)
        ? validation.validateSync(value)
        : validation(value || '', formState);
    }
  };
  const isValid = (name: string, value: FieldValue): boolean => {
    try {
      validate(name, value);
    } catch (error) {
      return false;
    }
    return true;
  };

  const isFormValid: boolean = isYupSchema(validations)
    ? validations.isValidSync(formState)
    : Object.keys(validations)
        .map((name) => isValid(name, formState[name]))
        .every(Boolean);

  const updateErrorMessagesBySchema = (validations: Yup.AnySchema) => {
    try {
      validations.validateSync(formState, {
        abortEarly: false,
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors = (error as ValidationError).inner.reduce<
          Partial<UseFormErrorState>
        >((acc, { path, message }) => {
          if (path && !acc[path]) {
            acc[path] = message;
          }
          return acc;
        }, {});
        return setErrorMessages(() => errors);
      }

      logger.error(
        // bug
        // eslint-disable-next-line
        `updateErrorMessages: unknown exception for "${name}, "`,
        error
      );
    }
  };

  const updateErrorMessages = (
    name: string,
    value: FieldValue,
    forceValidation = false
  ): void => {
    let errorMessage: ErrorMessage;

    if (
      (isYupSchema(validations) || typeof validations[name] !== 'undefined') &&
      (value !== '' || forceValidation)
    ) {
      try {
        validate(name, value || initialState[name]);
      } catch (error) {
        if (error instanceof Error) {
          errorMessage = error.message;
        }
      }
    }

    setErrorMessages((errors) => {
      const newErrors = { ...errors };

      if (errors[name] && !errorMessage) {
        delete newErrors[name];
      }
      if (errorMessage) {
        newErrors[name] = errorMessage;
      }

      return newErrors;
    });
  };

  const checkAllValidations = (forceValidations = false): void => {
    if (isYupSchema(validations)) {
      return updateErrorMessagesBySchema(validations);
    }

    Object.keys(validations).forEach((name) => {
      updateErrorMessages(name, formState[name], forceValidations);
    });
  };

  const handleSubmit = ($event: FormEvent): void => {
    $event.preventDefault();
    checkAllValidations(true);
    if (isFormValid) {
      setStorageValue(initialState);
      callbackOnSubmit(formState);
    }
  };

  const getErrorByFieldName = (name: string): ErrorMessage => {
    return errorMessages[name];
  };

  const isFieldValid = (fieldName: string, forceCheck = false): boolean => {
    if (forceCheck) {
      return isValid(fieldName, formState[fieldName]);
    }

    return !(
      formState[fieldName] === undefined || getErrorByFieldName(fieldName)
    );
  };

  const wouldNewValueBeValid = (fieldName: string, newValue: FieldValue) => {
    return isValid(fieldName, newValue);
  };

  const handleChange = (
    $event: ChangeEvent<FormElement>,
    validateOnChange?: boolean
  ): void => {
    $event.persist();
    const { name, value, type } = $event.target;
    const currentValue =
      type === 'checkbox' ? ($event.target as HTMLInputElement).checked : value;

    if (type === 'file' && ($event.target as HTMLInputElement).files) {
      const files = ($event.target as HTMLInputElement).files || [];

      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];

        updateErrorMessages(name, currentValue);
        fileToBase64(file).then((content) => {
          if (content && typeof content === 'string') {
            const newFile = { name: file.name, content, size: file.size };
            const newState = [...formState[name], newFile];
            updateErrorMessages(name, newState);

            if (wouldNewValueBeValid(name, newState)) {
              setFormState((state) => {
                return {
                  ...state,
                  [name]: newState,
                };
              });
            }
          }
        });
      }
      return;
    }

    if (name in errorMessages || validateOnChange) {
      updateErrorMessages(name, currentValue);
    }
    setFormState((state) => ({ ...state, [name]: currentValue }));
  };

  const handleChangeValue =
    (name: string) =>
    (value: FieldValue): void => {
      setFormState((state) => ({ ...state, [name]: value }));
      updateErrorMessages(name, value);
    };

  const handleBlur = (data: FocusEvent<FormElement> | string): void => {
    const name = typeof data === 'string' ? data : data.target.name;

    updateErrorMessages(name, formState[name], true);
  };

  const onRemoveFile =
    (fieldName: string) => (fileToRemove: FormFile) => () => {
      setFormState((oldState) => {
        const oldFiles: FormFile[] = oldState[fieldName];
        const newFiles: FormFile[] = oldFiles.filter(
          (file) => file !== fileToRemove
        );

        updateErrorMessages(fieldName, newFiles);

        return {
          ...oldState,
          [fieldName]: newFiles,
        };
      });
    };

  const setFieldValue = (
    fieldName: string,
    value: FieldValue,
    disableValidation?: boolean
  ) => {
    setFormState((oldFormState) => ({ ...oldFormState, [fieldName]: value }));
    if (!disableValidation) {
      updateErrorMessages(fieldName, value);
    }
  };

  const setFieldErrorMessage = (fieldName: string, errorMessage: string) => {
    setErrorMessages((errors) => {
      return { ...errors, [fieldName]: errorMessage };
    });
  };

  const setStorageValueRef = useRef(setStorageValue);
  setStorageValueRef.current = setStorageValue;

  useEffect(() => {
    if (stogrageKey) {
      setStorageValueRef?.current(formState);
    }
  }, [formState, stogrageKey]);

  return {
    formState,
    isFormValid,
    isFieldValid,
    getErrorByFieldName,
    handleSubmit,
    handleChangeValue,
    handleChange,
    handleBlur,
    onRemoveFile,
    setFieldValue,
    setFieldErrorMessage,
    setFormState, // only used in charging station configurator TODO: after Refactor charging station remove it PLEASE!
  };
};

export default useForm;
