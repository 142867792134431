import { useCallback, useEffect, useState } from 'react';
import { Key, UseKeyPressCallbacks } from './interfaces';

const useKeyPress = (
  targetKey: Key,
  callbacks: UseKeyPressCallbacks
): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);
  const { onKeyDown, onKeyUp, onKeyPressedChange } = callbacks;

  const downHandler = useCallback(
    ($event: KeyboardEvent): void => {
      if ($event.key === targetKey) {
        setKeyPressed(() => true);

        onKeyDown && onKeyDown($event);
        onKeyPressedChange && onKeyPressedChange(keyPressed);
      }
    },
    [targetKey, onKeyDown, onKeyPressedChange, keyPressed]
  );

  const upHandler = useCallback(
    ($event: KeyboardEvent) => {
      if ($event.key === targetKey) {
        setKeyPressed(() => false);

        onKeyUp && onKeyUp($event);
        onKeyPressedChange && onKeyPressedChange(keyPressed);
      }
    },
    [targetKey, onKeyUp, onKeyPressedChange, keyPressed]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }
  }, [downHandler, upHandler]);

  return keyPressed;
};
export default useKeyPress;
