import { useEffect, useState } from 'react';
import { UseViewportLazyLoad } from './interface';

const useViewportLazyLoad = ({
  elementRef,
  disableLazyLoad = false,
  initialValue = false,
  rootMargin = `300px 0px`,
}: UseViewportLazyLoad): boolean => {
  const [isLoad, setIsLoad] = useState(initialValue);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      disableLazyLoad ||
      typeof IntersectionObserver === 'undefined' ||
      !elementRef
    ) {
      setIsLoad(true);
      return;
    }

    if (elementRef.current) {
      const io = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsLoad(true);
              observer.disconnect();
            }
          });
        },
        {
          rootMargin,
        }
      );

      io.observe(elementRef.current);

      return () => {
        io.disconnect();
      };
    }
    // eslint-disable-next-line
  }, [elementRef, disableLazyLoad]);

  return isLoad;
};

export default useViewportLazyLoad;
