import { useCallback, useMemo } from 'react';
import useSafeRouter from '../useSafeRouter/useSafeRouter';
import {
  ParsedURLSearchParams,
  URLSearchParamsMergeOptions,
  URLSearchParamsMutation,
} from './types';

export const useQueryParams = () => {
  const { asPath, push, replace } = useSafeRouter();

  const urlSearchParams = useMemo(
    () => new URLSearchParams(asPath.split('?')[1]),
    [asPath]
  );

  const params = useMemo(() => {
    const result: ParsedURLSearchParams = {};

    for (const [key, value] of urlSearchParams) {
      if (key in result) {
        const current = result[key]!;
        result[key] = Array.isArray(current)
          ? [...current, decodeURIComponent(value)]
          : [current, decodeURIComponent(value)];
        continue;
      }

      result[key] = decodeURIComponent(value);
    }

    return result;
  }, [urlSearchParams]);

  const prepare = useCallback(
    (
      data: URLSearchParamsMutation,
      { clear }: URLSearchParamsMergeOptions = { clear: false }
    ) => {
      const result = new URLSearchParams(
        clear ? undefined : urlSearchParams.toString()
      );

      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'undefined') {
          result.has(key) && result.delete(key);
          continue;
        }

        if (Array.isArray(value)) {
          result.delete(key);
          value.forEach((v) => result.append(key, encodeURIComponent(v)));
          continue;
        }

        result.set(key, encodeURIComponent(value));
      }

      result.sort();

      return result.toString();
    },
    [urlSearchParams]
  );

  const merge = (url: string) => {
    const [path, search] = url.split('?');

    if (!search || !search.trim()) {
      return path;
    }

    const fromURL = new URLSearchParams(search);
    const visitedKeys: string[] = [];
    const result = new URLSearchParams(
      fromURL.has('_clean') ? undefined : urlSearchParams.toString()
    );

    fromURL.delete('_clean');

    for (const [key, value] of fromURL.entries()) {
      if (!visitedKeys.includes(key) && result.has(key)) {
        result.delete(key);
      }

      if (visitedKeys.includes(key)) {
        result.append(key, value);
      } else {
        result.set(key, value);
      }

      visitedKeys.push(key);
    }

    result.sort();

    return `${path}?${result.toString()}`;
  };

  const set = (
    data: URLSearchParamsMutation,
    { clear, replace: shouldReplace }: URLSearchParamsMergeOptions = {
      replace: false,
    }
  ) => {
    const [basePath, currentSearch] = asPath.split('?');
    const newSearch = prepare(data, { clear });

    if ((currentSearch ?? '') === newSearch) {
      return;
    }

    (shouldReplace ? replace : push)(
      `${basePath}${!!newSearch ? '?' : ''}${newSearch}`,
      undefined,
      { shallow: true }
    );
  };

  return {
    merge,
    params,
    prepare,
    set,
  };
};
