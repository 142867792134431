import clsx from 'clsx';
import styles from './Icon.module.scss';
import type { IconProps } from './interfaces';
import { useIcon } from './useIcon';

const Icon = ({
  variant,
  className,
  isOutlined = false,
  size,
  ...additionalProps
}: IconProps) => {
  const { data: svgData, isSuccess } = useIcon(variant, size, isOutlined);

  if (!svgData || !isSuccess) {
    return null;
  }

  return (
    <i
      {...additionalProps}
      className={clsx(styles.icon, styles[size], className)}
      dangerouslySetInnerHTML={{ __html: svgData }}
    />
  );
};

export default Icon;
