import React from 'react';
import Slider from '../Slider/Slider';
import type { TeaserTileGroupProps } from './interfaces';
import styles from './TeaserTileGroup.module.scss';

const TeaserTileGroup = ({ children }: TeaserTileGroupProps) => {
  return (
    <div className={styles.base}>
      <Slider className={styles.slider}>{children}</Slider>
    </div>
  );
};

export default TeaserTileGroup;
