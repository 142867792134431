export enum ButtonSubmitState {
  Initial = 'initial',
  Pending = 'pending',
  Info = 'info',
  Fail = 'fail',
  Success = 'success',
}

export const DEFAULT_STATE_MESSAGES = {
  pending: 'Sende …',
  fail: 'Fehlgeschlagen',
  success: 'Erfolgreich versandt!',
  info: 'Mehr Infos',
};
