import useIsMounted from '@/hooks/useIsMounted/useIsMounted';
import { animated, useSpring, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import useKeyPress from '../../../hooks/useKeyPress/useKeyPress';
import Icon from '../Icon/Icon';
import styles from './Modal.module.scss';
import type { ModalProps } from './interfaces';

const Modal = ({
  children,
  onClose,
  rearLayer,
  mainLayerVisible = true,
  variant = 'gradient',
  disableCloseIcon = false,
  backdropClassName,
  contentCentered = false,
}: ModalProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const closeRef = React.useRef<HTMLDivElement>(null);
  const closeCrossRef = React.useRef<HTMLSpanElement>(null);
  const isMounted = useIsMounted();

  const handleOnClose = ($event: React.MouseEvent) => {
    if (
      (modalRef && modalRef.current === $event.target) ||
      (closeRef && closeRef.current === $event.target) ||
      (closeCrossRef && closeCrossRef.current === $event.target)
    ) {
      $event.stopPropagation();
      onClose && onClose();
    }
  };

  useKeyPress('Escape', { onKeyDown: onClose });

  const transition = useTransition(mainLayerVisible, {
    from: {
      transform: contentCentered ? `translateY(50%) ` : `translateY(100%)`,
      opacity: 0,
      position: 'absolute',
    },
    enter: {
      transform: `translateY(0%)`,
      opacity: 1,
      position: 'relative',
    },
    leave: {
      transform: contentCentered ? `translateY(-50%) ` : `translateY(-100%)`,
      opacity: 0,
      position: 'absolute',
    },
    config: { mass: 1.25, tension: 210, friction: 26 },
  });

  const rearLayerAnimationProps = useSpring({
    opacity: !mainLayerVisible ? 1 : 0,
    pointerEvents: !mainLayerVisible ? 'all' : 'none',
    position: 'absolute',
  });

  useEffect(() => {
    if (isMounted && modalRef.current !== null) {
      modalRef.current.scrollTop = 0;
    }
  }, [isMounted]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.backdrop, styles[variant], backdropClassName)}
        onClick={handleOnClose}
      />
      {!disableCloseIcon && (
        <div className={styles.closeIcon} aria-label="Modal schließen">
          <div
            className={styles.closeIconClickSurface}
            onClick={handleOnClose}
            ref={closeRef}
          />
          <Icon variant={'action/close'} size="iconSize24" />
        </div>
      )}
      <div
        className={clsx(styles.modal)}
        onClick={handleOnClose}
        ref={modalRef}
      >
        {
          <animated.div
            style={{
              ...rearLayerAnimationProps,
              position: rearLayerAnimationProps.position as
                | React.CSSProperties['position']
                | any,
              pointerEvents: rearLayerAnimationProps.pointerEvents as
                | React.CSSProperties['pointerEvents']
                | any,
            }}
          >
            {rearLayer}
          </animated.div>
        }
        {children && (
          <div
            className={clsx(styles.layers, {
              [styles.contentCenter]: contentCentered,
            })}
          >
            {transition(
              (props, item) =>
                item && (
                  <animated.div
                    style={{
                      ...props,
                      position: props.position as
                        | React.CSSProperties['position']
                        | any,
                    }}
                    className={styles.layerChild}
                  >
                    {children}
                  </animated.div>
                )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
