import clsx from 'clsx';
import React, { useState } from 'react';
import { FormFieldType } from '../FormField/consts';
import FormField from '../FormField/FormField';
import styles from './FormFieldPassword.module.scss';
import type { FormFieldPasswordProps } from './interfaces';

const FormFieldPassword = ({
  label = 'Passwort',
  className,
  ...props
}: FormFieldPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormField
      label={label}
      className={clsx(styles.icon, className, {
        [styles.iconActive]: !showPassword,
        [styles.iconInactive]: showPassword,
      })}
      type={showPassword ? FormFieldType.Text : FormFieldType.Password}
      icon={showPassword ? 'user/eye' : 'user/eye-closed'}
      iconSize="iconSize24"
      onClickIcon={() => setShowPassword(!showPassword)}
      {...props}
    />
  );
};

export default FormFieldPassword;
