import React, { useState } from 'react';
import { NoticeBarContextValue } from './types';

const initialValue: NoticeBarContextValue = {
  setShowNoticeBar: () => void 0,
  showNoticeBar: true,
};

const NoticeBarContext =
  React.createContext<NoticeBarContextValue>(initialValue);

export const NoticeBarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showNoticeBar, setShowNoticeBar] = useState(true);

  const value: NoticeBarContextValue = {
    setShowNoticeBar,
    showNoticeBar,
  };

  return (
    <NoticeBarContext.Provider value={value}>
      {children}
    </NoticeBarContext.Provider>
  );
};

export default NoticeBarContext;
