const cache: { [key: string]: Promise<unknown> } = Object.create(null);

export const getJson = async <T>(
  url: RequestInfo | URL,
  options?: RequestInit
): Promise<T> => {
  const response = await fetch(url, {
    method: 'GET',
    ...options,
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const getJsonCached = <T>(url: string): Promise<T> => {
  if (!(url in cache)) {
    cache[url] = getJson(url);
  }
  return cache[url] as Promise<T>;
};

export const getText = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: 'GET',
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.text();
};

export const getTextCached = (url: string): Promise<string> => {
  if (!(url in cache)) {
    cache[url] = getText(url);
  }
  return cache[url] as Promise<string>;
};
