import Portal from '../../../components/Portal/Portal';
import { enercityEndpoints } from '../../../config/endpoints';
import { ButtonAlign } from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import { ClickableAction } from '../Clickable/consts';
import { HeadlineAlign, HeadlineLevel } from '../Headline/consts';
import Headline from '../Headline/Headline';
import LockBodyScroll from '../LockBodyScroll/LockBodyScroll';
import RichText from '../RichText/RichText';
import TeaserTile from '../TeaserTile/TeaserTile';
import TeaserTileGroup from '../TeaserTileGroup/TeaserTileGroup';
import { browsers } from './consts';
import type { OutdatedBrowserInfoProps } from './interfaces';
import styles from './OutdatedBrowserInfo.module.scss';

const OutdatedBrowserInfo = ({
  isVisible = false,
  onClose,
}: OutdatedBrowserInfoProps) => {
  const baseUrl = enercityEndpoints.assetsBaseUrl;

  return (
    <Portal id="outdated-browser-info">
      {isVisible && (
        <main className={styles.base}>
          <div className={styles.container}>
            <Headline
              className={styles.headline}
              tag="h1"
              align={HeadlineAlign.Center}
              level={HeadlineLevel.One}
            >
              Ihr aktueller Browser scheint veraltet zu sein!
            </Headline>
            <RichText type="typeFootnote" className={styles.note}>
              Damit diese Seite optimal dargestellt werden kann, benötigen Sie
              einen aktuellen Browser. Bitte wechseln Sie zu einer unserer
              Empfehlungen oder einem anderen modernen Browser.
            </RichText>
          </div>

          <div className={styles.tiles}>
            <TeaserTileGroup>
              {browsers.map(({ label, image, url }, idx) => (
                <TeaserTile
                  key={idx}
                  buttonLabel="Jetzt wechseln"
                  linkextern={url}
                  actionType={ClickableAction.Linkextern}
                  buttonIcon={'action/external-link'}
                >
                  <div className={styles.tileContent}>
                    <img
                      src={`${baseUrl}/assets/browser-images/${image}`}
                      alt={`Logo von ${label}`}
                    />
                    {label}
                  </div>
                </TeaserTile>
              ))}
            </TeaserTileGroup>
          </div>

          <div className={styles.container}>
            <RichText type="typeFootnote" className={styles.footnote}>
              Sie können sich die Seite trotzdem anzeigen lassen. Dies kann
              jedoch zu Darstellungsfehlern und Einschränkungen von Funktionen
              führen.
            </RichText>
            <ButtonLink
              align={ButtonAlign.Center}
              actionType={ClickableAction.Custom}
              onClick={() => onClose && onClose()}
            >
              Seite trotzdem anzeigen
            </ButtonLink>
          </div>
          <LockBodyScroll />
        </main>
      )}
    </Portal>
  );
};

export default OutdatedBrowserInfo;
