/* eslint-disable no-console */

const loggerFunction = () => {
  return {
    log: console.log.bind(console, 'log: %s'),
    error: console.error.bind(console, 'error: %s'),
    info: console.info.bind(console, 'info: %s'),
    warn: console.warn.bind(console, 'warn: %s'),
  };
};

export const logger = loggerFunction();
