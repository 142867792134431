import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import { HistoryContextValue, HistoryProviderProps } from './types';

const initialValue: HistoryContextValue = {
  history: [],
  push: () => void 0,
};

const HistoryContext = React.createContext<HistoryContextValue>(initialValue);

export const HistoryProvider = ({ children }: HistoryProviderProps) => {
  const [history, setHistory] = useState<string[]>([]);
  const push = (url: string) => setHistory([...history, url]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (history.length >= 1 && history[history.length - 1] === url) {
        return;
      }

      push(url);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  const value: HistoryContextValue = {
    history,
    push,
  };

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};

export default HistoryContext;
