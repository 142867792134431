import {
  calculateHeightByAspectRatio,
  createPlaceholderURL,
  createSrcset,
  ratioVariantToRect,
} from './helpers';
import { SourceProps } from './types';

const PictureSource = ({
  aspectRatio,
  width,
  breakpoint,
  path,
  defaultParams,
  defaultWidth,
  defaultAspectRatio,
  ratioVariants,
  hasMedia = true,
}: SourceProps) => {
  const w = width || breakpoint || defaultWidth;

  const rect = aspectRatio && ratioVariantToRect(ratioVariants?.[aspectRatio]);
  const calculatedHeight = !rect
    ? calculateHeightByAspectRatio(w, aspectRatio || defaultAspectRatio)
    : undefined;


  const params = {
    ...defaultParams,
    rect,
    ...(calculatedHeight ? { h: calculatedHeight } : {}),
    w,
  };

  return (
    <source
      key={breakpoint}
      media={hasMedia ? `(max-width: ${breakpoint}px)` : undefined}
      srcSet={createPlaceholderURL(path, params)}
      data-srcset={createSrcset(path, params)}
      data-aspect-ratio={aspectRatio}
    />
  );
};

export default PictureSource;
