const externalLinkMatcher = /(<a href="https.*?").*?>/gm;

export function setAttrsInExternalAnchors(value: string) {
  if (typeof value === 'string') {
    return value.replace(
      externalLinkMatcher,
      '$1 target="_blank" rel="noopener">'
    );
  }

  return value;
}
