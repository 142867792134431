import type { IconVariants } from '@uikit/components/Icon/interfaces';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Button from '../Button/Button';
import { ButtonAlign } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import LinkButton from '../LinkButton/LinkButton';
import { actionToIconMapper, ButtonLinkStyleVariant } from './consts';
import type { ButtonLinkProps } from './interfaces';

const ButtonLink = ({
  className,
  children,
  noClickable,
  align = ButtonAlign.Left,
  highlighted,
  styleVariant = ButtonLinkStyleVariant.TextLink,
  sizeVariant = 'medium',
  asButton = true,
  asATag = false,
  ButtonComponent = Button,
  disabled = false,
  iconPosition = 'left',
  hasOutlinedIcon = false,
  customIconSize,
  icon,
  disableTransition,
  ...props
}: ButtonLinkProps) => {
  let currentIcon: IconVariants;
  currentIcon = actionToIconMapper[props.actionType] ?? 'action/arrow-right';

  if (props.actionType === ClickableAction.Lightbox && props.videolink) {
    currentIcon = 'user/play';
  }

  const clickableProps = extractClickableProps(props);

  return styleVariant === ButtonLinkStyleVariant.Button ? (
    <Button
      {...props}
      align={align}
      className={className}
      icon={icon}
      noClickable={noClickable}
    >
      {children}
    </Button>
  ) : (
    <LinkButton
      className={className}
      icon={icon || currentIcon}
      variant={highlighted ? 'highlighted' : 'default'}
      iconPosition={iconPosition}
      disabled={disabled}
      size={sizeVariant}
      width={highlighted ? 'full' : 'auto'}
      align={align}
      as="button"
      asButton={asButton}
      asATag={asATag}
      onClick={() => {}}
      disableTransition={disableTransition}
      clickableProps={clickableProps}
    >
      {children}
    </LinkButton>
  );
};

export default ButtonLink;
