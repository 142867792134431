import config, { endpoints } from '@/config';
import { useMutation, UseMutationResult } from 'react-query';
import useLocalStorage from '../useLocalStorage/useLocalStorage';

const useCommandKLogin = (): [
  boolean,
  UseMutationResult<Response, unknown, string, unknown>
] => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('cmdk@loggedIn', false);
  const [, setCanLoadMarkerIo] = useLocalStorage('markerio', false);

  const mutationFn = async (password: string) => {
    // add Datalayer Events...

    return fetch(`${config.api.baseUrl}${endpoints.commandkLogin}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ password }),
    }).then((response) =>
      response.status === 200 ? response : Promise.reject(response)
    );
  };

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      setIsLoggedIn(true);
      setCanLoadMarkerIo(true);
    },
  });

  return [isLoggedIn, mutation];
};

export default useCommandKLogin;
