export enum ButtonAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Outline = 'outline',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonWidth {
  Full = 'full',
  Auto = 'auto',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
}

export enum ShowIcon {
  None = 'none',
  ShowIcon = 'iconShown',
}

export enum ShowAddon {
  None = 'none',
  NoBackground = 'noBackground',
  Plain = 'plain',
  Gradient = 'gradient',
  GradientMeinHGas = 'gradient-mein-h-gas',
}

export enum ButtonForm {
  EmobilityAdviceForm = 'emobilityAdviceForm',
}
