import Modal from '@/uikit/components/Modal/Modal';
import { animated, config, useTransition } from '@react-spring/web';
import { useEffect } from 'react';

import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import useLockBodyScroll from '@/hooks/useLockBodyScroll/useLockBodyScroll';
import useModal from '@/hooks/useModal/useModal';
import usePathname from '@/hooks/usePathname/usePathname';
import Portal from '../Portal/Portal';
import type { CompoundModalProps, ModalPortalProps } from './interfaces';

const CompoundModal = ({
  virtualPageUrl,
  modalComponent: ModalComponent = Modal,
  ...props
}: CompoundModalProps) => {
  const { pushVirtualPageView } = useDataLayer();
  const pathname = usePathname();

  useEffect(() => {
    if (virtualPageUrl) {
      pushVirtualPageView(`${pathname}/${virtualPageUrl}`);

      return () => {
        pushVirtualPageView(`${pathname}`);
      };
    }
  }, [pushVirtualPageView, virtualPageUrl, pathname]);

  useLockBodyScroll();
  return <ModalComponent {...props} />;
};

const ModalPortal = ({
  id,
  show,
  onClose,
  ...modalProps
}: ModalPortalProps) => {
  const { currentModalContextId, hideRearLayer, closeModal } = useModal();
  const isOpen =
    typeof show === 'undefined'
      ? currentModalContextId && currentModalContextId === id
      : show;

  const transitions = useTransition(isOpen, {
    from: { position: 'relative', opacity: 0 },
    enter: { opacity: 1, zIndex: 11 },
    leave: { opacity: 0 },
    config: config.gentle,
    onDestroyed: () => hideRearLayer(),
  } as const);

  return (
    <Portal id={id}>
      {transitions((props, item) => {
        return (
          item && (
            <animated.div style={props}>
              <CompoundModal {...modalProps} onClose={onClose ?? closeModal} />
            </animated.div>
          )
        );
      })}
    </Portal>
  );
};

export default ModalPortal;
