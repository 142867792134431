import AuthenticationProvider from '@/contexts/Authentication/Authentication.context';
import { CommandMenuProvider } from '@/contexts/CommandMenu/CommandMenu.context';
import { HistoryProvider } from '@/contexts/History/History.context';
import { HotkeyProvider } from '@/contexts/Hotkey/Hotkey.context';
import { MarkerIoProvider } from '@/contexts/MarkerIo/MarkerIo.context';
import { ModalProvider } from '@/contexts/Modal.context';
import { NoticeBarProvider } from '@/contexts/NoticeBar/NoticeBar.context';
import { UserDataProvider } from '@/contexts/UserData/UserData.context';
import useAuthenticationProviderSetup from '@/hooks/useAuthenticationProviderSetup/useAuthenticationProviderSetup';
import isIE from '@uikit/helpers/isIE';
import { PropsWithChildren, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ParallaxProvider } from 'react-scroll-parallax';
import OutdatedBrowserInfo from '../../uikit/components/OutdatedBrowserInfo/OutdatedBrowserInfo';
import type { ProvidersProps } from './types';

const Providers = ({ children }: PropsWithChildren<ProvidersProps>) => {
  const [isOldBrowser, setIsOldBrowser] = useState(false);

  useEffect(() => {
    if (isIE()) {
      const hideOutdatedBrowserInfo =
        localStorage.getItem('hide-outdated-browser-info') === 'true';

      setIsOldBrowser(!hideOutdatedBrowserInfo);
    }
  }, []);

  const handleOutdatedBrowserClose = () => {
    localStorage.setItem('hide-outdated-browser-info', JSON.stringify(true));
    setIsOldBrowser(false);
  };

  const queryClient = new QueryClient();
  const [authenticationState, authenticationDispatch] =
    useAuthenticationProviderSetup();

  return (
    <QueryClientProvider client={queryClient}>
      <MarkerIoProvider>
        <UserDataProvider>
          <AuthenticationProvider
            state={authenticationState}
            dispatch={authenticationDispatch}
          >
            <HistoryProvider>
              <HotkeyProvider>
                <ModalProvider>
                  <NoticeBarProvider>
                    <CommandMenuProvider>
                      <ParallaxProvider>
                        <ParallaxProvider>{children}</ParallaxProvider>
                      </ParallaxProvider>
                    </CommandMenuProvider>
                  </NoticeBarProvider>
                </ModalProvider>
              </HotkeyProvider>
            </HistoryProvider>
          </AuthenticationProvider>
        </UserDataProvider>
      </MarkerIoProvider>

      <OutdatedBrowserInfo
        isVisible={isOldBrowser}
        onClose={handleOutdatedBrowserClose}
      />
    </QueryClientProvider>
  );
};

export default Providers;
