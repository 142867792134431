import { useEffect, useMemo, useRef, useState } from 'react';
import { createVideoSources, findSize } from '../helpers';
import type { VideoInputDataSizes } from '../types';

export const useResponsiveVideo = (
  inputDataSizes: VideoInputDataSizes = {}
) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const { videoSources, videoSizes } = useMemo(() => {
    const videoSources = createVideoSources(inputDataSizes);
    const videoSizes = videoSources.map(({ size }) => size);

    return { videoSources, videoSizes };
  }, [inputDataSizes]);

  const [firstSize] = videoSizes;
  const [selectedSize, setSelectedSize] = useState<number>(firstSize);
  const [isLoaded, setIsLoaded] = useState<boolean | null>(null);

  const sources = useMemo(
    () => videoSources.filter(({ size }) => size === selectedSize),
    [selectedSize, videoSources]
  );

  useEffect(() => {
    resizeObserverRef.current?.disconnect();
    resizeObserverRef.current = new ResizeObserver(([entry]) => {
      if (!entry) {
        return;
      }

      const newSize = findSize(entry.contentRect.width, videoSizes);

      if (
        typeof newSize === 'undefined' ||
        newSize === selectedSize ||
        document.fullscreenElement
      ) {
        return;
      }

      setSelectedSize(() => newSize);
    });

    if (videoRef.current) {
      resizeObserverRef.current.observe(videoRef.current);
    }

    return () => {
      resizeObserverRef.current?.disconnect();
    };
  }, [videoSizes, selectedSize]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const listener = () => {
      setIsLoaded(true);
    };

    videoRef.current.addEventListener('loadeddata', listener);

    () => {
      videoRef.current?.removeEventListener('loadeddata', listener);
    };
  }, [isLoaded]);

  return { videoRef, selectedSize, sources, isLoaded };
};
