import clsx from 'clsx';
import React from 'react';
import type { LightboxProps } from './interfaces';
import styles from './Lightbox.module.scss';
import LockBodyScroll from '../LockBodyScroll/LockBodyScroll';
import Icon from '../Icon/Icon';

const Lightbox = ({
  children,
  onClose,
  showCloseIcon = true,
  active = true,
}: LightboxProps) => {
  return (
    <>
      <div className={clsx(styles.base, { [styles.visible]: active })}>
        {active && <LockBodyScroll />}

        <div onClick={onClose} className={styles.overlay} />
        {!!onClose && showCloseIcon && (
          <div className={styles.closeIcon} onClick={onClose}>
            <Icon variant={'action/close'} size="iconSize24" />
          </div>
        )}

        <div className={styles.content}>{active && <>{children}</>}</div>
      </div>
    </>
  );
};

export default Lightbox;
