export enum ClickableAction {
  Anchor = 'anchor',
  Linkintern = 'linkintern',
  Linkextern = 'linkextern',
  Phone = 'phone',
  Asset = 'asset',
  MailTo = 'mailto',
  Modal = 'modal',
  Lightbox = 'lightbox',
  EpilotJourney = 'epilotjourney',
  MSBookings = 'msBookings',
  Custom = 'custom',
  None = 'none',
}

export enum ClickableTarget {
  Blank = '_blank',
  Self = '_self',
}

export enum ClickableLinkOptions {
  WithClickableNot = 'withNoClickable',
  WithClickable = 'withClickable',
}

export enum ClickableLightboxOptions {
  Videobox = 'videobox',
  Iframebox = 'iframebox',
}
