import { logger } from '@/helpers/logger';
import useLocalStorage from '@/hooks/useLocalStorage/useLocalStorage';
import { Reducer, useReducer } from 'react';

type Settings<State> = {
  key: string;
  blackList?: (keyof State)[];
  whiteList?: (keyof State)[];
  validate?: (value: Partial<State>) => void;
} & (
  | { overwriteLocalStorage?: boolean }
  | {
      initializer: (initialValue: State, storageValue: Partial<State>) => State;
    }
);

function useReducerWithLocalStorage<S extends {}, A>(
  reducer: Reducer<S, A>,
  initialValue: S,
  { key, blackList, whiteList, validate, ...restSettings }: Settings<S>
) {
  const [storage, setStorage] = useLocalStorage<Partial<S>>(
    key,
    initialValue,
    validate
  );

  return useReducer(
    (state: S, action: A) => {
      const newState = reducer(state, action);
      const stateKeys = Object.keys(newState) as (keyof S)[];
      const stateForStorage = stateKeys.reduce<Partial<S>>((acc, key) => {
        if (blackList?.length && blackList.includes(key)) {
          return acc;
        }

        if (whiteList?.length && !whiteList.includes(key)) {
          return acc;
        }

        const value = newState[key];

        if (typeof value === 'undefined') {
          return acc;
        }

        return { ...acc, [key]: value };
      }, {});

      setStorage(stateForStorage);

      return newState;
    },
    initialValue,
    (initialValue) => {
      try {
        if (typeof window !== 'undefined') {
          if (!storage) {
            return initialValue;
          }

          if (validate?.(storage)) {
            return initialValue;
          }

          if ('initializer' in restSettings) {
            return restSettings.initializer(initialValue, storage);
          }

          return restSettings.overwriteLocalStorage
            ? {
                ...storage,
                ...initialValue,
              }
            : {
                ...initialValue,
                ...storage,
              };
        }
        return initialValue;
      } catch (error) {
        logger.error(error);
        return initialValue;
      }
    }
  );
}
export default useReducerWithLocalStorage;
