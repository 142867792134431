import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import { animated, config, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import React from 'react';
import LockBodyScroll from '../LockBodyScroll/LockBodyScroll';
import ModalCloseIcon from '../Modal/ModalCloseIcon/ModalCloseIcon';
import styles from './GuideBox.module.scss';
import type { GuideBoxOverlayProps } from './interfaces';

const GuideBoxOverlay = ({
  title,
  teaserText,
  fullText,
  name,
  close,
  coloured,
}: GuideBoxOverlayProps) => {
  const transitions = useTransition(true, {
    from: { position: 'relative', opacity: 0 },
    enter: { opacity: 1, zIndex: 9999 },
    leave: { opacity: 0 },
    config: config.gentle,
  });

  return (
    <ModalPortal
      id={`guide-${name}`}
      onClose={close}
      modalComponent={({ children }) => <>{children}</>}
    >
      {transitions((props, item) => {
        return (
          item && (
            <animated.div
              style={{
                ...props,
                position: props.position as
                  | React.CSSProperties['position']
                  | any,
              }}
            >
              <LockBodyScroll />
              <article
                className={clsx(
                  styles.base,
                  styles.overlay,
                  coloured ? styles.coloured : styles.white
                )}
                key={name}
              >
                <ModalCloseIcon onClick={close} />
                <div className={styles.container}>
                  <h3 className={styles.title}>{title}</h3>
                  <div className={clsx(styles.rightSide)}>
                    <div className={styles.fullTextWrapper}>
                      <p className={styles.teaser}>{teaserText}</p>
                      <div
                        className={styles.teaser}
                        {...getSafeInnerHTMLProp(fullText)}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </animated.div>
          )
        );
      })}
    </ModalPortal>
  );
};

export default GuideBoxOverlay;
