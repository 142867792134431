import useEpilot from '@/hooks/useEpilot/useEpilot';
import usePathname from '@/hooks/usePathname/usePathname';
import clsx from 'clsx';
import React, { useCallback, useContext, useState } from 'react';
import Portal from '../../../components/Portal/Portal';
import ModalContext from '../../../contexts/Modal.context';
import useDataLayer from '../../../hooks/useDataLayer/useDataLayer';
import mailToParams from '../../helpers/generateMailToParams';
import parsePhoneNumber from '../../helpers/parsePhoneNumber';
import scrollToElementByID from '../../helpers/scrollToElementByID';
import stripHTMLTags from '../../helpers/stripHTMLTags';
import toID from '../../helpers/toID';
import Lightbox from '../Lightbox/Lightbox';
import Link from '../Link/Link';
import Video from '../Video/Video';
import styles from './Clickable.module.scss';
import { ClickableAction, ClickableLightboxOptions } from './consts';
import { getClickableUrl } from './helpers';
import {
  ClickableProps,
  ClickablePropsAnchor,
  ClickablePropsModal,
} from './interfaces';

const Clickable = (props: ClickableProps): JSX.Element => {
  const {
    onClick,
    asButton,
    asATag,
    clickContext,
    customDataLayer,
    withoutDataLayer,
  } = props;
  const { currentModalContextId, showModal } = useContext(ModalContext);
  const [lightboxIsActive, setLightboxIsActive] = useState(false);
  const { pushDataLayerEvent } = useDataLayer();
  const pathname = usePathname();
  const customClickUrl = getClickableUrl(props);

  const pushCustomClickEvent = useCallback(
    ($event: React.SyntheticEvent<HTMLElement>) => {
      if (withoutDataLayer === true) {
        return;
      }

      pushDataLayerEvent({
        event: 'customClick',
        clickContext: stripHTMLTags(clickContext),
        customClickText: $event.currentTarget.innerText,
        customPagePath: pathname.split('?')[0],
        customClickUrl,
        ...customDataLayer,
      });
    },
    [
      withoutDataLayer,
      pushDataLayerEvent,
      clickContext,
      pathname,
      customClickUrl,
      customDataLayer,
    ]
  );

  const handleOnClick = useCallback(
    ($event: React.MouseEvent<HTMLElement>) => {
      pushCustomClickEvent($event);
      onClick && onClick($event);
    },
    [pushCustomClickEvent, onClick]
  );

  const handleOnClickAnchor = useCallback(
    ($event: React.MouseEvent<HTMLAnchorElement>): void => {
      $event.stopPropagation();
      pushCustomClickEvent($event);

      if (props.actionType === ClickableAction.Anchor) {
        $event.preventDefault();

        scrollToElementByID(props.anchor);
      }

      onClick && onClick($event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(props as ClickablePropsAnchor).anchor, onClick]
  );

  const handleOnClickWithModal = useCallback(
    ($event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      pushCustomClickEvent($event);

      if (props.actionType === ClickableAction.Modal) {
        showModal(
          props.modallink !== currentModalContextId ? props.modallink : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.actionType,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (props as ClickablePropsModal).modallink,
      showModal,
      currentModalContextId,
    ]
  );

  const extendPathname = (pathname: string, paramsWithHash: string = '') => {
    const currentUrl = new URL(pathname, 'https://www.enercity.de'); // base url is ignored
    const [params, hash] = paramsWithHash.split('#');

    const newSearchParams = new URLSearchParams([
      ...currentUrl.searchParams.entries(),
      ...new URLSearchParams(params).entries(),
    ]).toString();
    const newHash = (hash && `#${hash}`) || currentUrl.hash;

    return (
      currentUrl.pathname + (newSearchParams && `?${newSearchParams}`) + newHash
    );
  };

  const handleOnClickWithLightbox = (
    $event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if ($event) {
      pushCustomClickEvent($event);
    }
    setLightboxIsActive(!lightboxIsActive);
  };

  const journeyId =
    props.actionType === ClickableAction.EpilotJourney
      ? props.journeyId
      : undefined;

  const { showJourney } = useEpilot();

  const handleOnClickWithEpilotJourney = (
    $event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if ($event) {
      pushCustomClickEvent($event);
    }

    showJourney(journeyId);
  };

  const linkProps = {
    title: props.title,
    tabIndex: props.tabIndex || undefined,
    id: props.id,
    className: props.className,
    ...(typeof props['aria-expanded'] !== 'undefined'
      ? { 'aria-expanded': props['aria-expanded'] }
      : {}),
    ...(typeof props['aria-controls'] !== 'undefined'
      ? { 'aria-controls': props['aria-controls'] }
      : {}),
  };

  const Tag = asATag
    ? 'a'
    : asButton || props.actionType === ClickableAction.Custom
    ? 'button'
    : 'a';

  return (
    <>
      {props.actionType === ClickableAction.Linkextern ? (
        <a
          target={props.target}
          rel={props.nofollow ? 'nofollow noopener' : 'noopener'}
          href={props.linkextern}
          {...linkProps}
          onClick={handleOnClick}
        >
          {props.children}
        </a>
      ) : props.actionType === ClickableAction.Linkintern ? (
        <Link
          to={extendPathname(props.linkintern, props.params)}
          onClick={handleOnClick}
          {...linkProps}
        >
          {props.children}
        </Link>
      ) : props.actionType === ClickableAction.Anchor ? (
        <a
          href={`#${props.anchor}`}
          {...linkProps}
          id={props.anchor ? `scroll-to-${toID(props.anchor)}` : props.id}
          onClick={handleOnClickAnchor}
        >
          {props.children}
        </a>
      ) : props.actionType === ClickableAction.Phone ? (
        <a href={'tel:' + parsePhoneNumber(props.phone)} {...linkProps}>
          {props.children}
        </a>
      ) : props.actionType === ClickableAction.Asset && props.asset ? (
        <a
          href={props.asset.path}
          target={props.target}
          download={props.download ?? true}
          {...linkProps}
        >
          {props.children}
        </a>
      ) : props.actionType === ClickableAction.MailTo ? (
        <a
          href={`mailto:${props.email}${mailToParams(
            props.emailCC ? props.emailCC : '',
            props.emailBCC ? props.emailBCC : '',
            props.emailSubject ? props.emailSubject : '',
            props.emailText ? props.emailText : ''
          )}`}
          target="_blank"
          rel="noreferrer"
          {...linkProps}
        >
          {props.children}
        </a>
      ) : props.actionType === ClickableAction.Modal ? (
        <button
          {...linkProps}
          className={clsx(linkProps.className, styles.button)}
          onClick={handleOnClickWithModal}
          type="button"
        >
          {props.children}
        </button>
      ) : props.actionType === ClickableAction.Lightbox ? (
        <>
          <Tag {...linkProps} onClick={handleOnClickWithLightbox}>
            {props.children}
          </Tag>
          <Portal
            id={`lightbox-${
              props.lightbox === ClickableLightboxOptions.Videobox
                ? props.videolink
                : props.iframelink
            }`}
          >
            <Lightbox
              active={lightboxIsActive}
              onClose={handleOnClickWithLightbox}
            >
              {props.lightbox === ClickableLightboxOptions.Videobox && (
                <Video src={`${props.videolink}`} videoName={props.videoName} />
              )}
              {props.lightbox === ClickableLightboxOptions.Iframebox && (
                <iframe
                  className={styles.iframe}
                  src={props.iframelink}
                  title={props.title}
                />
              )}
            </Lightbox>
          </Portal>
        </>
      ) : props.actionType === ClickableAction.EpilotJourney ? (
        <Tag {...linkProps} onClick={handleOnClickWithEpilotJourney}>
          {props.children}
        </Tag>
      ) : props.actionType === ClickableAction.MSBookings ? (
        <>
          <Tag {...linkProps} onClick={handleOnClickWithLightbox}>
            {props.children}
          </Tag>
          <Portal id={`lightbox-msbookings-${props.calendar}-${props.topic}`}>
            <Lightbox
              active={lightboxIsActive}
              onClose={handleOnClickWithLightbox}
            >
              <iframe
                className={styles.iframe}
                src={`https://termine.${
                  props.domain === 'enercity' ? 'enercity' : 'enercity-netz'
                }.de/owa/calendar/${
                  props.calendar
                }@enercityappointments.onmicrosoft.com/bookings/#${
                  props.topic
                }`}
                title={props.calendar}
              />
            </Lightbox>
          </Portal>
        </>
      ) : props.actionType === ClickableAction.Custom ? (
        <Tag onClick={handleOnClick} {...linkProps} type="button">
          {props.children}
        </Tag>
      ) : (
        <Tag onClick={handleOnClick} {...linkProps}>
          {props.children}
        </Tag>
      )}
    </>
  );
};

export default Clickable;
