import styles from './IFramePlayer.module.scss';
import { IFramePlayerProps } from './types';

const IFramePlayer = ({ onPlay, title, ...props }: IFramePlayerProps) => (
  <iframe
    {...props}
    allowFullScreen
    className={styles.base}
    onLoad={onPlay}
    title={title}
  />
);

export default IFramePlayer;
