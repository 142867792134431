import config from '@/config';
import Script from 'next/script';

const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID;

const GoogleTagManagerScript = () => {
  // disable script if no GTM ID is provided and no consent manager ID is provided
  // https://enerdigi.enercity.de/wiki/pages/viewpage.action?pageId=142020250

  if (!config.consentManagerId && !GOOGLE_TAG_MANAGER_ID) {
    return null;
  }

  return (
    <Script
      id="google-tag-manager-script"
      className="cmplazyload"
      strategy="lazyOnload"
      data-cmp-vendor="s905"
      type="text/plain"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');`,
      }}
    />
  );
};

export default GoogleTagManagerScript;
