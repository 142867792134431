import type { RichTextSize, RichTextVariant } from './interfaces';
import styles from './RichText.module.scss';

export const richTextStyles: {
  [key in RichTextVariant | RichTextSize]: string;
} = {
  default: styles.default,
  'default-white': styles.defaultWhite,
  small: styles.small,
  medium: styles.medium,
};
