import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useEffect } from 'react';

const HandelsblattHelper = () => {
  const { params } = useQueryParams();

  const hbid = params.hbid;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!hbid) return;

    const date = new Date();
    const futureDate = date.setDate(date.getDate() + 30);

    document.cookie = `handelsblatt.clickid=${hbid}; expires=${new Date(
      futureDate
    ).toUTCString()}; domain=.enercity.de; path=/;`;
  }, [hbid]);

  return null;
};

export default HandelsblattHelper;
