import { toVariables } from '@uikit/helpers/responsiveProperties';
import styles from './StackItem.module.scss';
import type { StackItemProps } from './types';

const StackItem = ({ children, ...responsiveStyles }: StackItemProps) => {
  return (
    <div className={styles.base} style={toVariables(responsiveStyles)}>
      {children}
    </div>
  );
};

export default StackItem;
