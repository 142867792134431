import { debounce } from '@/helpers/debounce';
import { firstIfArray } from '@uikit/utils/firstIfArray';
import { useCallback, useEffect, useState } from 'react';
import useDataLayer from '../useDataLayer/useDataLayer';
import { useQueryParams } from '../useQueryParams/useQueryParams';
import { UseScriptConfigVariant } from '../useScript/scriptVariants';
import useScript from '../useScript/useScript';

type EpilotTrackingEvent = {
  journeyId: string;
  type: string;
  payload: Record<string, unknown>;
};

const epilotEventTypeMap: Record<string, string> = {
  'EPILOT/EXIT_FULLSCREEN': 'epilot_exit',
  'EPILOT/USER_EVENT/PAGE_VIEW': 'epilot_pageview',
  'EPILOT/USER_EVENT/PROGRESS': 'epilot_progress',
};

const isValidEpilotTrackingEvent = (data: any): data is EpilotTrackingEvent => {
  const { journeyId, type } = data;
  return journeyId && typeof type === 'string' && type.startsWith('EPILOT');
};

const getEventName = (type: string): string => {
  return epilotEventTypeMap[type] ?? type.replace('/', '_').toLowerCase();
};

const journeyIds: string[] = [];

/*
  Aus unerklärlichen Gründen triggert er das useEffect für den Query-
  Parameter exakt 18 Mal. Da die alle gleichzeitig ausgeführt werden,
  hilft es nicht, mit einem Ref zu prüfen, ob der Query-Parameter
  schon verarbeitet wurde. Das Debouncen des Handlers funktioniert aber
  leider auch nur, wenn die ge-debouncte Funktion nicht im Hook erstellt
  wird, sonst verliert er beim Rerender die Timeouts im Hintergrund.

  Es ist also wirklich nicht schön so, aber es scheint die einzige
  Lösung zu sein, die tatsächlich funktioniert aktuell.
*/
const showJourneyFromParams = debounce(
  (
    journeyId: string,
    args: {
      scriptState: ReturnType<typeof useScript>;
      setCurrentJourneyId: (value: string) => void;
      onShow: () => void;
    }
  ) => {
    if (!journeyId || args.scriptState !== 'ready' || !window.__epilot) {
      return;
    }

    args.setCurrentJourneyId(journeyId);

    if (window.__epilot.isInitialized(journeyId)) {
      window.__epilot.enterFullScreen(journeyId);
    }

    args.onShow();
  },
  200
);

const useEpilot = () => {
  const scriptState = useScript(UseScriptConfigVariant.EPilot);
  const { pushDataLayerEvent } = useDataLayer();
  const [currentJourneyId, setCurrentJourneyId] = useState<string | null>(null);

  const { params } = useQueryParams();

  const journeyIdFromParams = firstIfArray(params?.journeyId);

  const handleEpilotTrackingEvent = useCallback(
    ({ data }: MessageEvent<EpilotTrackingEvent>) => {
      if (!isValidEpilotTrackingEvent(data)) {
        return;
      }

      const { payload, type, ...eventData } = data;

      const fields = Object.fromEntries(
        Object.entries({ ...eventData, ...payload }).map(([key, value]) => [
          `epilot_${key}`,
          value,
        ])
      );

      pushDataLayerEvent({
        event: getEventName(type),
        epilot_isCustomEvent: 'false',
        ...fields,
      });
    },
    [pushDataLayerEvent]
  );

  const showJourney = useCallback(
    (journeyId?: string) => {
      if (!journeyId || scriptState !== 'ready' || !window.__epilot) {
        return;
      }
      setCurrentJourneyId(() => journeyId);

      if (window.__epilot.isInitialized(journeyId)) {
        window.__epilot.enterFullScreen(journeyId);
      }

      pushDataLayerEvent({
        event: 'epilot_pageview',
        epilot_isCustomEvent: 'true',
        epilot_journeyId: journeyId,
        epilot_path: `epilot-journey/${journeyId}/step-1`,
      });
    },
    [pushDataLayerEvent, scriptState]
  );

  useEffect(() => {
    if (!currentJourneyId) {
      return;
    }

    if (!journeyIds.includes(currentJourneyId)) {
      window.addEventListener('message', handleEpilotTrackingEvent);
      journeyIds.push(currentJourneyId);

      if (window.__epilot && !window.__epilot.isInitialized(currentJourneyId)) {
        window.__epilot.init([
          {
            journeyId: currentJourneyId,
            mode: 'full-screen',
          },
        ]);
        window.__epilot.enterFullScreen(currentJourneyId);
      }
    }

    return () => {
      window.removeEventListener('message', handleEpilotTrackingEvent);
      journeyIds.splice(journeyIds.indexOf(currentJourneyId), 1);
    };
  }, [handleEpilotTrackingEvent, currentJourneyId]);

  useEffect(() => {
    if (currentJourneyId || !journeyIdFromParams) {
      return;
    }

    showJourneyFromParams(journeyIdFromParams, {
      onShow: () => {
        pushDataLayerEvent({
          event: 'epilot_pageview',
          epilot_isCustomEvent: 'true',
          epilot_journeyId: journeyIdFromParams,
          epilot_path: `epilot-journey/${journeyIdFromParams}/step-1`,
        });
      },
      scriptState,
      setCurrentJourneyId,
    });
  }, [currentJourneyId, journeyIdFromParams, pushDataLayerEvent, scriptState]);

  return { showJourney };
};

export default useEpilot;
