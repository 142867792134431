import config from '@/config';
import { LambdaResponse } from '@/types/lambda';
import { useMutation } from 'react-query';
import { LoginCredentials, LoginResponse } from './types';

export const useLogin = () => {
  const mutationFn = async (
    state: LoginCredentials
  ): Promise<LambdaResponse<LoginResponse>> => {
    return fetch(`${config.api.baseUrl}login`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(state),
    }).then(async (response) => {
      if (response.status !== 200) {
        throw await response.json();
      }

      return await response.json();
    });
  };

  const { mutateAsync: login } = useMutation(mutationFn);

  return login;
};
