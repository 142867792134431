import React, { useState } from 'react';
import useResizeObserver from '../useResizeObserver/useResizeObserver';
import { UseMeasure } from './interfaces';

const useMeasure: UseMeasure = (
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
  useBoundingClientRect = false
) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  useResizeObserver(ref, ([entry]) => {
    if (entry) {
      setBounds(
        useBoundingClientRect
          ? entry.target.getBoundingClientRect()
          : entry.contentRect
      );
    }
  });

  return bounds;
};

export default useMeasure;
