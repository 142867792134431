import { useSafeLayoutEffect } from '../useSafeLayoutEffect/useSafeLayoutEffect';

export default function useLockBodyScroll(): void {
  useSafeLayoutEffect((): (() => void) | void => {
    // Get original body overflow
    if (document) {
      // Prevent scrolling on mount
      document.body.classList.add('scroll-lock');
      document.documentElement.classList.add('scroll-lock');
      // Re-enable scrolling when component unmounts
      return (): void => {
        document.body.classList.remove('scroll-lock');
        document.documentElement.classList.remove('scroll-lock');
      };
    }
  }, []); // Empty array ensures effect is only run on mount and unmount
}
