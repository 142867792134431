import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import styles from './FormFieldLength.module.scss';
import type { FormFieldLengthProps } from './interfaces';

const FormFieldLength = ({
  value = '',
  maxLength,
  show = true,
  isOnGradient = false,
}: FormFieldLengthProps) => {
  const isValid = value.length <= maxLength;
  const style = useSpring({ opacity: Number(show), config: { duration: 300 } });

  return (
    <span>
      <animated.div
        className={clsx(styles.default, {
          [styles.invalid]: !isValid,
          [styles.onGradient]: isOnGradient,
        })}
        style={style}
      >
        {value.length}/{maxLength}
      </animated.div>
    </span>
  );
};

export default FormFieldLength;
