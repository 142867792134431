export const AuthenticationModalReasonsText = {
  default: {
    headline: 'Anmelden',
    subText: 'Melden Sie sich bitte mit Ihrem enercity Zugang an.',
  },
  benefits: {
    headline: 'Anmelden',
    subText:
      'Melden Sie sich bitte mit Ihrem enercity Zugang an um den Gutschein zu erhalten.',
  },
};

export type AuthenticationModalReasons =
  keyof typeof AuthenticationModalReasonsText;
