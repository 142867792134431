import { toKebabCase } from '@uikit/utils/convertCase';
import React from 'react';

export type Breakpoints =
  | 'initial'
  | 'xxs'
  | 'xsbetween'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl';

export type ResponsiveProperty<T> = T | { [B in Breakpoints]?: T };

export type ResponsivePropertySet<
  TProperties extends keyof React.CSSProperties
> = {
  [P in TProperties]?: ResponsiveProperty<React.CSSProperties[P]>;
};

const withDefaultUnit = (value: number | string, unit: string = 'rem') => {
  if (typeof value === 'number') {
    return `${value}${unit}`;
  }

  return value;
};

export const toVariables = <TProperties extends keyof React.CSSProperties>(
  set: ResponsivePropertySet<TProperties>
) => {
  return Object.fromEntries(
    Object.entries(set).flatMap(([key, value]) => {
      const propertyName = toKebabCase(key);

      if (typeof value === 'object' && value) {
        return Object.entries(value).map(([breakpoint, responsiveValue]) => [
          `--${propertyName}-${breakpoint}`,
          withDefaultUnit(responsiveValue),
        ]);
      }

      return [
        [
          `--${propertyName}-initial`,
          withDefaultUnit(value as number | string),
        ],
      ];
    })
  );
};

export const toMediaQueries = <TProperties extends keyof React.CSSProperties>(
  set: ResponsivePropertySet<TProperties>
) => {
  return Object.entries(set)
    .map(([key, value]) => {
      if (value && typeof value === 'object') {
        return Object.keys(value)
          .filter((breakpoint) => breakpoint !== 'initial')
          .map((breakpoint) => {
            const propertyName = toKebabCase(key);

            return propertyName + ':' + breakpoint;
          })
          .join(' ');
      }
      return '';
    })
    .filter(Boolean)
    .join(' ');
};
