export const browsers = [
  {
    label: 'Google Chrome',
    image: 'chrome.svg',
    url: 'https://www.google.com/intl/de_de/chrome/',
  },
  {
    label: 'Mozilla Firefox',
    image: 'firefox.svg',
    url: 'https://www.mozilla.org/de/firefox/new/',
  },
  {
    label: 'Microsoft Edge',
    image: 'edge.png',
    url: 'https://www.microsoft.com/de-de/windows/microsoft-edge',
  },
];
