import type { Asset } from '@uikit/types';
import type { StaticImageData } from 'next/image';

export const getImageData = (
  data: { asset: Asset } | { src: StaticImageData | string; alt: string }
): Pick<Asset, 'path' | 'name' | 'rects' | 'metadata'> | null => {
  if ('src' in data) {
    return {
      path: typeof data.src === 'string' ? data.src : data.src.src,
      name: data.alt,
    };
  }

  if (!data.asset || typeof data.asset.path === 'undefined') {
    return null;
  }

  const { path, name, rects, metadata } = data.asset;
  return { path, name, rects, metadata };
};
