import clsx from 'clsx';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import styles from './LinkButton.module.scss';
import type { LinkButtonProps } from './interfaces';

const LinkButton = ({
  disableTransition = false,
  size = 'medium',
  width = 'auto',
  align = 'center',
  ...props
}: LinkButtonProps): JSX.Element => {
  const classes = clsx(
    styles.base,
    styles[props.variant],
    styles[size],
    styles[width],
    styles[align],
    {
      [styles.disabled]: props.disabled,
      [styles.transition]: !disableTransition,
    },
    props.className
  );

  const clickableProps =
    props.as === 'button' && props.clickableProps
      ? extractClickableProps(props.clickableProps)
      : null;

  const iconSize = {
    tiny: 'iconSize18',
    small: 'iconSize20',
    medium: 'iconSize24',
    large: 'iconSize24',
  } as const;

  const content = props.icon ? (
    <>
      {props.iconPosition === 'left' && (
        <Icon
          variant={props.icon}
          size={iconSize[size]}
          className={clsx(styles.icon, styles.iconLeft)}
        />
      )}
      {props.children}
      {props.iconPosition === 'right' && (
        <Icon
          variant={props.icon}
          size={iconSize[size]}
          className={clsx(styles.icon, styles.iconRight)}
        />
      )}
    </>
  ) : (
    props.children
  );

  if (props.as === 'div') {
    return <div className={classes}>{content}</div>;
  }

  if (props.as === 'button') {
    if (clickableProps) {
      return (
        <Clickable
          asButton={props.asButton}
          asATag={props.asATag}
          className={classes}
          {...clickableProps}
        >
          {content}
        </Clickable>
      );
    }

    return (
      <Clickable
        actionType={ClickableAction.Custom}
        className={classes}
        onClick={props.onClick}
        clickContext={props.clickContext}
        asButton
        customDataLayer={props.customDataLayer}
        {...(typeof props['aria-expanded'] !== 'undefined'
          ? { 'aria-expanded': props['aria-expanded'] }
          : {})}
        {...(typeof props['aria-controls'] !== 'undefined'
          ? { 'aria-controls': props['aria-controls'] }
          : {})}
      >
        {content}
      </Clickable>
    );
  }

  if (props.linkExtern) {
    return (
      <a className={classes} href={props.href} target={props.target}>
        {content}
      </a>
    );
  }

  return (
    <Link className={classes} to={props.href} target={props.target}>
      {content}
    </Link>
  );
};

export default LinkButton;
