import clsx from 'clsx';
import React from 'react';
import Box from '../Box/Box';
import { BoxWidth } from '../Box/consts';
import type { ModalContentProps } from './interfaces';
import styles from './ModalContent.module.scss';

const ModalContent = ({
  width,
  maxHeight = false,
  noPadding = false,
  className = '',
  children,
  background = 'white',
  withSpaceBetweenChildren = false,
  noMargin = false,
}: ModalContentProps) => {
  return (
    <div className={styles.wrapper}>
      <Box
        className={clsx(
          styles.content,
          styles[background],
          {
            [styles.noPadding]: noPadding,
            [styles.smedium]: width === BoxWidth.SMedium,
            [styles.socialMedium]: width === BoxWidth.SocialMedium,
            [styles.withSpaceBetweenChildren]: withSpaceBetweenChildren,
            [styles.noMargin]: noMargin,
          },
          className
        )}
        width={width}
        maxHeight={maxHeight}
      >
        {children}
      </Box>
    </div>
  );
};

export default ModalContent;
