import clsx from 'clsx';
import dynamic from 'next/dynamic';
import type NextLink from 'next/link';
import isIE from '../../helpers/isIE';

const RouterLink = dynamic(() => import('next/link'), { ssr: false });

interface LinkProps
  extends Omit<React.ComponentPropsWithoutRef<typeof NextLink>, 'href'> {
  to: string;
}

const Link = ({ to, children, ...props }: LinkProps) => {
  if (
    isIE() ||
    (typeof window !== 'undefined' && window.USE_MAGNOLIA_RENDERER)
  ) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }

  const className = clsx('cta_', props.className);

  /**
   * Next.js Workaround.
   * The Link with hash is not scrolled to proper position by router.
   * Because of that, the Link is replaced by `a`.
   */
  if (/\/.+#/.test(to)) {
    return (
      <a href={to} {...props} className={className}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink href={to || '/'} {...props} className={className}>
      {children}
    </RouterLink>
  );
};

export default Link;
