import clsx from 'clsx';
import React, { useEffect } from 'react';
import styles from './ErrorMessage.module.scss';
import type { ErrorMessageProps } from './interfaces';
import useDataLayer from '../../../hooks/useDataLayer/useDataLayer';

const ErrorMessage = ({
  children,
  className,
  withWhiteColor = false,
  hasRelativePosition = false,
}: ErrorMessageProps) => {
  const { pushDataLayerEvent } = useDataLayer();

  useEffect(() => {
    pushDataLayerEvent({
      event: 'Fehlermeldung',
      errorMsgContent: children?.toString(),
    });
  }, [pushDataLayerEvent, children]);

  return (
    <div
      className={clsx(
        styles.base,
        {
          [styles.whiteColor]: withWhiteColor,
          [styles.relativePosition]: hasRelativePosition,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default ErrorMessage;
