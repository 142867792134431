const SPECIAL_KEYS: Record<string, string> = {
  '+': 'plus',
  '-': 'minus',
  ' ': 'space',
};

export const getStrokesFromKeyboardEvent = ({
  altKey,
  ctrlKey,
  key,
  metaKey,
  shiftKey,
}: KeyboardEvent) => {
  const strokes = [
    altKey && 'alt',
    ctrlKey && 'control',
    metaKey && 'meta',
    shiftKey && 'shift',
    SPECIAL_KEYS[key.toLowerCase()] ?? key.toLowerCase(),
  ].filter(Boolean) as string[];

  strokes.sort((a, b) => a.localeCompare(b));

  return strokes.join('+');
};
