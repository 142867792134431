import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import { WIDESCREEN_BREAKPOINT_ASPECT_RATIO_CONFIGS } from '../LegacyPicture/consts';
import IFramePlayer from './IFramePlayer';
import styles from './Video.module.scss';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import VideoPlayerWithData from './VideoPlayer/VideoPlayerWithData';
import VimeoPlayer from './VimeoPlayer';
import { identifyProvider, prepareSource } from './helpers';
import { VideoPlayerRenderProps, VideoProps, VideoState } from './types';

const Video = ({
  children,
  className,
  muted = false,
  src,
  thumbnail,
  title,
  videoName,
}: VideoProps) => {
  const [state, setState] = useState<VideoState>('ready');
  const [thumbnailAsset, setThumbnailAsset] = useState(thumbnail);
  const source = useMemo(() => prepareSource(src), [src]);

  useEffect(() => {
    if (!thumbnail && source?.getThumbnail) {
      source.getThumbnail().then((asset) => setThumbnailAsset(asset));
    }
  }, [source, thumbnail]);

  const player = useMemo(() => {
    if (!source) {
      return;
    }

    const renderProps: VideoPlayerRenderProps = {
      onPlay: () => setState('playing'),
      src: source.src,
      title: title ?? '',
    };

    if (children) {
      return children(renderProps);
    }

    const Player =
      identifyProvider(source.src) === 'vimeo' ? VimeoPlayer : IFramePlayer;

    return <Player {...renderProps} />;
  }, [children, source, title]);

  return (
    <div className={clsx(styles.base, className)}>
      {state !== 'ready' && (
        <div className={styles.player}>
          {videoName ? (
            <VideoPlayerWithData
              autoPlay
              controls
              muted={muted}
              onPlay={() => setState('playing')}
              videoName={videoName}
              width="100%"
            />
          ) : (
            player
          )}
        </div>
      )}

      <button
        className={clsx(styles.activator, {
          [styles.isHidden]: state === 'playing',
        })}
        onClick={() => setState('loading')}
      >
        {thumbnailAsset && (
          <LegacyPicture
            asset={thumbnailAsset}
            breakpointAspectRatioConfigs={
              WIDESCREEN_BREAKPOINT_ASPECT_RATIO_CONFIGS
            }
            className={styles.thumbnail}
            fitCover
            title={title}
          />
        )}
      </button>
    </div>
  );
};

Video.IFramePlayer = IFramePlayer;
Video.VimeoPlayer = VimeoPlayer;
Video.VideoPlayer = VideoPlayer;
Video.VideoPlayerWithData = VideoPlayerWithData;

export default Video;
