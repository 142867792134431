import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import clsx from 'clsx';
import React from 'react';
import convertParagraphsToBR from '../../helpers/convertParagraphsToBR';
import { HeadlineAlign, HeadlineColor, HeadlineLevel } from './consts';
import styles from './Headline.module.scss';
import type { HeadlineProps } from './interfaces';

const Headline = React.memo<HeadlineProps>(
  ({
    children,
    tag = 'h2',
    level = HeadlineLevel.Two,
    headlineColor = HeadlineColor.Dark,
    align = HeadlineAlign.Center,
    className,
    headlineElementClassName,
    htmlId,
    id,
    light = false,
    subText,
    isCustomWidth = false,
    spacing = 'normal',
    badgeLabel,
    ...additionalProps
  }): JSX.Element => {
    const wrapperClasses = clsx(
      styles[align],
      { [styles.wrapperMaxWidth]: isCustomWidth },
      {
        [styles.level1]: level === HeadlineLevel.One,
        [styles.level2]: level === HeadlineLevel.Two,
        [styles.level3]: level === HeadlineLevel.Three,
        [styles.level4]: level === HeadlineLevel.Four,
        [styles.lightFontWeight]: light,
      },
      className
    );

    const classes = clsx(
      styles.headline,
      styles[headlineColor],
      headlineElementClassName
    );

    const headlineElement = React.createElement(tag, {
      className: classes,
      ...additionalProps,
      id: htmlId || id,
      ...(typeof children === 'string'
        ? getSafeInnerHTMLProp(convertParagraphsToBR(children || ''))
        : { children }),
    });

    return (
      <header className={wrapperClasses}>
        {badgeLabel && (
          <div
            className={clsx(styles.badge, styles[headlineColor], styles[align])}
          >
            {badgeLabel}
          </div>
        )}
        {headlineElement}
        {subText && (
          <div
            className={clsx(
              styles.subText,
              {
                [styles.subTextCenter]: align === HeadlineAlign.Center,
                [styles.spacingLarge]: spacing === 'large',
              },
              styles[headlineColor]
            )}
            {...(typeof subText === 'string'
              ? getSafeInnerHTMLProp(subText)
              : { children: subText })}
          />
        )}
      </header>
    );
  }
);

Headline.displayName = 'Headline';

export default Headline;
