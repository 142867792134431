import { logger } from '@/helpers/logger';
import { useState } from 'react';

function useSessionStorage<T>(
  key: string | undefined,
  initialValue: T | ((storageState?: T) => T)
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const isInitialValueFunction = initialValue instanceof Function;
    const stateInitializer = isInitialValueFunction
      ? initialValue
      : () => initialValue;

    try {
      if (typeof window !== 'undefined' && key) {
        // Get from local storage by key
        const item = window.sessionStorage.getItem(key);
        // Parse stored json or if none return initialValue
        const parsedItem = item && JSON.parse(item);

        if (isInitialValueFunction) {
          // @ts-ignore
          return stateInitializer(parsedItem);
        }

        if (!parsedItem) {
          // @ts-ignore
          return stateInitializer();
        }

        return parsedItem;
      }

      // @ts-ignore
      return stateInitializer();
    } catch (error) {
      // If error also return initialValue
      logger.error(error);

      // @ts-ignore
      return stateInitializer();
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined' && key) {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.error(error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useSessionStorage;
