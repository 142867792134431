export enum AssetVariant {
  Tiny = 'tiny',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

export enum AssetType {
  Svg = 'svg',
  Jpg = 'jpg',
  Png = 'png',
  Pdf = 'pdf',
  Usdz = 'usdz',
}

export enum Align {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type AssetVariantPaths = {
  [key in AssetVariant]: string;
};

export interface AssetPaths extends AssetVariantPaths {
  webp?: AssetVariantPaths;
}

export interface Asset {
  path: string;
  name: string;
  paths?: AssetPaths;
  traced?: string;
  hasWebp?: boolean;
  type?: AssetType;
  calculatedAspectRatio?: number;
  dimensions?: Dimensions;
  rects?: AssetRects;
  metadata?: MetaData;
  placeholderBase64?: string;
}

export interface AssetRects {
  [key: string]: AssetRect;
}

export interface AssetRect {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface Dimensions {
  width?: number;
  height?: number;
}

export interface MetaData extends Dimensions {
  /**
   * Name of the asset. When creating a new asset, the asset name will be equal
   *  to the file name if this field is empty when saving the dialog.
   */
  title?: string;

  /**
   * A caption to display next to asset
   */
  caption?: string;

  /**
   * An account of the resource. Description may include but is not limited to:
   *  an abstract, a table of contents, a graphical representation, or a free-text account of the resource.
   */
  description?: string;

  /**
   * An entity responsible for making the resource available. Examples of a Publisher include a person, an organization, or a service.
   */
  publisher?: string;

  /**
   * A related resource.
   */
  relation?: string;

  /**
   * A related resource from which this item is derived.
   */
  source?: string;

  /**
   * Information about rights held in and over the resource. ie Copyright.
   */
  rights?: string;

  /**
   * An entity responsible for making contributions to the resource.
   */
  contributor?: string[];

  /**
   * The topic of the resource. Typically, the subject will be represented using keywords, key phrases, or classification codes.
   */
  subject?: string[];
  date?: Date | string;
}

export type AspectRatio =
  | '21:9'
  | '16:10'
  | '16:9'
  | '4:3'
  | '9:16'
  | '1:1'
  | '2:1'
  | string;
