import useBreakPoint from '@/hooks/useBreakPoint/useBreakPoint';
import clsx from 'clsx';
import { useRef } from 'react';
import useViewportLazyLoad from '../../../hooks/useViewportLazyLoad/useViewportLazyLoad';
import isARFileSupported from '../../helpers/isARFileSupported';
import styles from './LegacyPicture.module.scss';
import { DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS } from './consts';
import { getImageData } from './helpers';
import ImageTransformerPicture from './image-transformer/ImageTransformerPicture';
import { LegacyPictureProps } from './interfaces';

const LegacyPicture = ({
  className,
  arFile,
  fitCover = false,
  withShadow = false,
  disableLazyLoad = false,
  scaleToFit,
  defaultAspectRatio,
  breakpointAspectRatioConfigs = DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS,
  ...props
}: LegacyPictureProps) => {
  const breakpoint = useBreakPoint('to');
  const pictureRef = useRef<HTMLDivElement>(null);
  const loadFullImage = useViewportLazyLoad({
    elementRef: pictureRef,
    disableLazyLoad,
  });

  const imageData = getImageData(props);

  if (!imageData) {
    return null;
  }

  const { path, name, rects, metadata } = imageData;

  const classes = clsx(
    styles.wrapper,
    {
      [styles.withShadow]: withShadow,
    },
    className
  );

  const ratioConfig = breakpointAspectRatioConfigs.find(
    (config) => breakpoint <= config.breakpoint
  );

  const style = {
    aspectRatio:
      String(ratioConfig?.aspectRatio ?? defaultAspectRatio ?? '')?.replace(
        ':',
        '/'
      ) || undefined,
  };

  const alt = metadata?.caption ?? metadata?.title ?? name;
  const title = props.title ?? metadata?.title ?? name;

  if (path.endsWith('.svg')) {
    return (
      <div className={classes} ref={pictureRef}>
        <img
          src={path}
          alt={alt}
          title={title}
          style={style}
          className={clsx(styles.visible, styles.imgBase, {
            [styles.fitCover]: fitCover,
            [styles.scaleToFit]: scaleToFit,
          })}
        />
      </div>
    );
  }

  return (
    <div className={classes} ref={pictureRef}>
      <ImageTransformerPicture
        path={path}
        breakpointAspectRatioConfigs={breakpointAspectRatioConfigs}
        ratioVariants={rects}
        imgProps={{
          style,
          alt,
          title,
          className: clsx(styles.imgBase, {
            [styles.visible]: loadFullImage,
            [styles.fitCover]: fitCover,
            [styles.scaleToFit]: scaleToFit,
          }),
        }}
        {...props}
      />
    </div>
  );
};

const LegacyPictureWithAR = (props: LegacyPictureProps) => {
  if (props.arFile && isARFileSupported()) {
    return (
      <a rel="ar" href={props.arFile.path}>
        <LegacyPicture {...props} />
      </a>
    );
  }
  return <LegacyPicture {...props} />;
};

export default LegacyPictureWithAR;
