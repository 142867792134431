import { Asset } from '@uikit/types';
import { VideoSource } from './types';

const YOUTUBE_PARAMS = [
  'autohide=1',
  'autoplay=1',
  'color=white',
  'controls=2',
  'enablejsapi=1',
  'modestbranding=1',
  'playsinline=1',
  'rel=0',
  'showinfo=0',
];

const VIMEO_PATTERN =
  /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/;
const YOUTUBE_PATTERN =
  /(?:youtu\.be\/|youtube\S*\.com\/\S*(?:v=|embed\/))([0-9A-Za-z_-]{10}[048AEIMQUYcgkosw])/;

const getVimeoId = (src: string) => {
  const matches = src.match(VIMEO_PATTERN);
  return matches && matches.length > 0 ? matches[1] : undefined;
};

const getVimeoThumbnail = async (id: string): Promise<Asset> => {
  const res = await fetch(`https://vimeo.com/api/v2/video/${id}.json`, {
    method: 'GET',
  });

  if (res.status !== 200) {
    return {
      name: 'video-thumbnail',
      path: '',
    };
  }

  const metadata = await res.json();

  return {
    name: 'video-thumbnail',
    path: metadata[0].thumbnail_large,
  };
};

const getYouTubeId = (src: string) => {
  const matches = src.match(YOUTUBE_PATTERN);
  return matches && matches.length > 0 ? matches[1] : undefined;
};

const getYouTubeThumbnail = async (id: string): Promise<Asset> => {
  const { status } = await fetch(
    `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
    { mode: 'no-cors' }
  );

  return {
    name: 'video-thumbnail',
    path:
      status === 200
        ? `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
        : `https://img.youtube.com/vi/${id}/hqdefault.jpg`,
  };
};

export const identifyProvider = (src: string) => {
  if (/vimeo/.test(src)) {
    return 'vimeo';
  }

  if (/youtu\.?be/.test(src)) {
    return 'youtube';
  }

  return undefined;
};

export const prepareSource = (src?: string): VideoSource | undefined => {
  if (!src) {
    return;
  }

  const provider = identifyProvider(src);

  if (!provider) {
    return { getThumbnail: undefined, src };
  }

  switch (provider) {
    case 'vimeo': {
      const id = getVimeoId(src);

      return {
        getThumbnail: id ? () => getVimeoThumbnail(id) : undefined,
        src,
      };
    }
    case 'youtube': {
      const id = getYouTubeId(src);

      return {
        getThumbnail: id ? () => getYouTubeThumbnail(id) : undefined,
        src: id
          ? `https://www.youtube-nocookie.com/embed/${id}?${YOUTUBE_PARAMS.join(
              '&'
            )}`
          : src,
      };
    }
  }
};
