import { UserDataAction, UserDataState } from './interfaces';

export const userDataReducer = (
  state: UserDataState,
  action: UserDataAction
) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...action.payload,
      };
    case 'SET_ZIP_CODE':
      return {
        ...state,
        zipCode: action.payload,
      };
    case 'SET_USER_ADDRESS':
      return action.payload;
    case 'RESET_USER_ADDRESS':
      return (() => {
        const {
          zipCode,
          city,
          cityKey,
          streetKey,
          streetName,
          streetNumber,
          ...rest
        } = state;
        return rest;
      })();
    case 'SET_CITY':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_STREET':
      return {
        ...state,
        streetName: action.payload.streetName ?? state.streetName,
        streetNumber: action.payload.streetNumber ?? state.streetNumber,
        streetKey: action.payload.streetKey ?? state.streetKey,
      };
    case 'SET_BENEFITS':
      return {
        ...state,
        benefits: {
          ...state.benefits,
          ...action.payload,
        },
      };
    case 'RESET_BENEFITS':
      return {
        ...state,
        benefits: {},
      };
    default:
      return state;
  }
};
