import * as ajax from '@/helpers/ajax';
import type { IconSize } from './interfaces';
import getIconFileUrl from './getIconFileUrl';
import { removeSVGDimensions } from './removeSVGDimensions/removeSVGDimensions';

const fetchIcon = async (
  variant: string,
  size: IconSize,
  isOutlined: boolean
): Promise<string> => {
  if (!variant) {
    return '';
  }

  const svgUrl = getIconFileUrl(variant, size, isOutlined);

  try {
    const svg = await ajax.getText(svgUrl);

    return removeSVGDimensions(svg);
  } catch {
    throw new Error(`Can not fetch icon: ${svgUrl}`);
  }
};

export default fetchIcon;
