import { useQuery } from 'react-query';
import fetchIcon from './fetchIcon';
import { IconSize } from './interfaces';

export const useIcon = (
  variant: string = '',
  size: IconSize,
  isOutlined: boolean
) => {
  return useQuery(
    ['icon', variant, size, isOutlined],
    () => fetchIcon(variant, size, isOutlined),
    {
      staleTime: Infinity,
    }
  );
};
