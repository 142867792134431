import React from 'react';
import { createPortal } from 'react-dom';
import type { PortalProps } from './interfaces';

function createElementInBody(id: string) {
  const element = document.createElement('div');
  element.id = id;
  document.body.appendChild(element);

  return element;
}

const Portal = ({ id, children }: PortalProps): React.ReactPortal | null => {
  const [element, setElement] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    let currentElement = document.getElementById(id);
    let isElementCreated = false;

    if (!currentElement) {
      currentElement = createElementInBody(id);
      isElementCreated = true;
    }

    setElement(currentElement);

    return () => {
      if (isElementCreated && currentElement?.parentElement) {
        currentElement.parentElement.removeChild(currentElement);
      }
    };
  }, [id]);

  if (typeof window === 'undefined' || element === null) {
    return null;
  }

  return createPortal(children, element);
};

export default React.memo(Portal);
