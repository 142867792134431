import AklamioHelper from '@/components/AklamioHelper/AklamioHelper';
import GoogleTagManagerScript from '@/components/GoogleTagManager/GoogleTagManagerScript';
import HandelsblattHelper from '@/components/HandelsblattHelper/HandelsblattHelper';
import Providers from '@/components/Providers/Providers';
import UTMTracker from '@/components/UTMTracker/UTMTracker';
import { currentProjectName } from '@/config';
import { isProductionMode } from '@/helpers/isProductionMode';
import '@uikit/styles/global.scss';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import 'normalize.css';
import smoothscroll from 'smoothscroll-polyfill';

const CommandMenuBase = dynamic(
  () => import('@uikit/components/CommandMenu/Base/Base')
);

const App = ({ Component, pageProps, router }: AppProps) => {
  if (typeof window !== 'undefined') {
    // smooth scrolling for Safari, IE9+ etc
    smoothscroll.polyfill();
    sessionStorage.setItem('first-load', 'true');
  }

  return (
    <>
      {isProductionMode && <GoogleTagManagerScript />}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover"
        />

        {router.asPath === '/' && currentProjectName === 'enercity' && (
          <script type="application/ld+json" id="google-site-name">{`{
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "enercity",
            "url" : "https://www.enercity.de"
          }`}</script>
        )}
      </Head>

      <Providers>
        <Component {...pageProps} />

        <CommandMenuBase />

        <AklamioHelper />
        <UTMTracker />
        <HandelsblattHelper />
      </Providers>
    </>
  );
};

export default App;
