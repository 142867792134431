import clsx from 'clsx';
import styles from './Divider.module.scss';
import type { DividerProps } from './types';

const Divider = (props: DividerProps) => {
  return (
    <div
      className={clsx(styles.base, props.className, {
        [styles.fullWidth]: props.fullWidth,
      })}
    >
      <hr />
      {'children' in props && (
        <span className={styles.children}>{props.children}</span>
      )}
      {'dividerText' in props && (
        <span className={styles.text}>{props.dividerText}</span>
      )}
      <hr />
    </div>
  );
};

export default Divider;
