export const debounce = <TFunc extends (...args: any[]) => void>(
  f: TFunc,
  waitFor: number
) => {
  if (typeof window === 'undefined') {
    return f;
  }

  let timeoutId: number;

  return (...args: Parameters<TFunc>) => {
    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => f.call(null, ...args), waitFor);
  };
};
