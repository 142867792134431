const isARFileSupported = (): boolean => {
  if (typeof document === 'undefined') {
    return false;
  }

  const a = document.createElement('a');
  return a.relList.supports('ar');
};

export default isARFileSupported;
