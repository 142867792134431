import {
  toMediaQueries,
  toVariables,
} from '@uikit/helpers/responsiveProperties';
import clsx from 'clsx';
import styles from './Stack.module.scss';
import StackItem from './StackItem/StackItem';
import type { StackProps } from './types';

const Stack = ({
  alignItems,
  children,
  className,
  flexDirection,
  gap,
  inheritValues = false,
  justifyContent,
  wrap,
  id,
}: StackProps) => {
  const vars = { gap, flexDirection, justifyContent, alignItems };

  return (
    <div
      id={id}
      className={clsx(
        styles.base,
        !inheritValues && styles.reset,
        wrap && styles.isWrapping,
        className
      )}
      data-responsive-breakpoints={toMediaQueries(vars)}
      style={{
        ...toVariables(vars),
      }}
    >
      {children}
    </div>
  );
};

Stack.Item = StackItem;

export default Stack;
