import { logger } from '@/helpers/logger';
import { useState } from 'react';

// Hook code by https://usehooks.com/useLocalStorage/
function useLocalStorage<T>(
  key: string,
  initialValue: T,
  validate?: (value: T) => void
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (typeof window !== 'undefined') {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);

        if (item) {
          validate?.(JSON.parse(item));
          return JSON.parse(item);
        }

        // Parse stored json or if none return initialValue
        return initialValue;
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.error(error);
      window.localStorage.removeItem(key);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.error(error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
