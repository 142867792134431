import React from 'react';

export const useWithMiddleware = <State extends {}, Action>(
  [state, dispatch]: [State, React.Dispatch<Action>],
  middlewares?: ((state: State, action: Action) => void)[],
  afterDispatchMiddleWares?: ((state: State, action: Action) => void)[]
): [State, React.Dispatch<Action>] => {
  const currentActionRef = React.useRef<Action>();

  React.useEffect(() => {
    if (!currentActionRef.current) {
      return;
    }

    for (const middleware of afterDispatchMiddleWares || []) {
      middleware(state, currentActionRef.current);
    }
  }, [afterDispatchMiddleWares, state]);

  const dispatchUsingMiddleware = (action: Action) => {
    for (const middleware of middlewares || []) {
      middleware(state, action);
    }
    currentActionRef.current = action;
    dispatch(action);
  };

  return [state, dispatchUsingMiddleware];
};
