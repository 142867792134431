import ModalContext from '@/contexts/Modal.context';
import React from 'react';

function useModal() {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContext provider');
  }

  return context;
}

export default useModal;
