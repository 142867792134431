import clsx from 'clsx';
import React from 'react';
import styles from './Box.module.scss';
import { BoxWidth, BoxVariant } from './consts';
import type { BoxProps } from './interfaces';

const Box = ({
  children,
  withShadow = true,
  centered = false,
  className,
  width = BoxWidth.Default,
  maxHeight = false,
  variant = BoxVariant.Default,
  ...props
}: BoxProps) => {
  const classes = clsx(
    styles.base,
    styles[width],
    {
      [styles.alternative]: variant === BoxVariant.Alternative,
      [styles.shadow]: withShadow,
      [styles.centered]: centered,
      [styles.maxHeight]: maxHeight,
    },
    className
  );

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default Box;
