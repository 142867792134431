import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { ClickableAction } from './consts';
import type { ClickableProps } from './interfaces';

const baseUrl = getBaseUrl();

export const getClickableUrl = (props: ClickableProps) => {
  if (props.actionType === ClickableAction.Linkextern) {
    return props.linkextern;
  }

  if (props.actionType === ClickableAction.Linkintern) {
    return new URL(props.linkintern, baseUrl).toString();
  }

  if (props.actionType === ClickableAction.Anchor) {
    return new URL(props.anchor, baseUrl).toString();
  }
};
