import { logger } from '@/helpers/logger';
import useIsMounted from '@/hooks/useIsMounted/useIsMounted';
import Player from '@vimeo/player';
import { useEffect, useId, useRef } from 'react';
import { VimeoPlayerProps } from './types';

const VimeoPlayer = ({ className, onPlay, src }: VimeoPlayerProps) => {
  const id = useId();
  const isMounted = useIsMounted();
  const player = useRef<Player | null>(null);

  useEffect(() => {
    if (!isMounted || !!player.current) {
      return;
    }

    try {
      player.current = new Player(id, {
        autoplay: true,
        background: true,
        dnt: true,
        loop: true,
        muted: true,
        responsive: true,
        url: src,
      });
    } catch (ex) {
      logger.error(
        `[VimeoPlayer] The player couldn't be created on element "#${id}" for "${src}".`,
        ex
      );
    }

    return () => {
      player.current?.destroy().catch(() => void 0);
    };
  }, [id, isMounted, src]);

  useEffect(() => {
    if (!player.current || !onPlay) {
      return;
    }

    const handleOnReady = () => onPlay();

    player.current.on('play', handleOnReady);
  }, [isMounted, onPlay, player]);

  return <div className={className} id={id} />;
};

export default VimeoPlayer;
