import VideoPlayer from './VideoPlayer';
import { useVideoData } from './hooks/useVideoData';
import type { VideoPlayerWithDataProps } from './types';

const VideoPlayerWithData = ({
  videoName,
  ...props
}: VideoPlayerWithDataProps) => {
  const { data } = useVideoData(videoName);
  if (!data) {
    return null;
  }

  return <VideoPlayer {...props} videoData={data} />;
};

export default VideoPlayerWithData;
