export enum BoxWidth {
  Large = 'large',
  EngCalorificValueCalculator = 'engCalorificValueCalculator',
  Default = 'default',
  Medium = 'medium',
  SMedium = 'smedium',
  SocialMedium = 'socialmedium',
  Small = 'small',
  LMedium = 'lmedium',
  Auto = 'auto',
  JobApply = 'jobApply',
}

export enum BoxVariant {
  Default = 'default',
  Alternative = 'alternative',
}
