import React, { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
  callback: ResizeObserverCallback
) => {
  const observer = new ResizeObserver(callback);

  useEffect(() => {
    if (ref && ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
    //eslint-disable-next-line
  }, [ref]);

  return;
};

export default useResizeObserver;
