import { getSafeInnerHTML } from '@/helpers/getSafeInnerHTML';
import { setAttrsInExternalAnchors } from '@uikit/helpers/setAttrsInExternalAnchors';
import clsx from 'clsx';
import styles from './RichText.module.scss';
import { richTextStyles } from './consts';
import type { RichTextProps } from './interfaces';

const RichText = ({
  children,
  className,
  variant = 'default',
  size = 'default',
  type = 'typeText',
  listWeight = 'default',
  align = 'auto',
  autoColor,
  ...additionalProps
}: RichTextProps): JSX.Element => {
  const classes = clsx(
    styles.base,
    richTextStyles[variant],
    richTextStyles[size],
    styles[align],
    className,
    {
      [styles.listRegular]: listWeight === 'regular',
      [styles.autoColor]: autoColor,
    }
  );

  const footnoteClasses = clsx(styles.footnoteBase);
  const highlightedClasses = clsx(styles.highlightedBase);

  const props: Partial<RichTextProps> =
    type === 'typeFootnote'
      ? {
          className: footnoteClasses,
          ...additionalProps,
        }
      : type === 'typeHighlighted'
      ? {
          className: highlightedClasses,
          ...additionalProps,
        }
      : {
          className: classes,
          ...additionalProps,
        };

  if (typeof children === 'string') {
    props.dangerouslySetInnerHTML = {
      __html: setAttrsInExternalAnchors(getSafeInnerHTML(children)),
    };
  } else {
    props.children = children;
  }

  return <div {...props} />;
};

export default RichText;
