/**
 *
 * @param id HTML ID of element we want to scroll to. If this does not exist
 * in the DOM, this function will not do anything.
 * @param disableOffset Usually, we want horizontal offset (100px), so the
 * header does not overlap the element we scroll to. In case we do not want
 * the offset, we can set this to `true`.
 */
const scrollToElementByID = (
  id: string,
  disableOffset = false,
  behavior: ScrollBehavior = 'smooth'
): void => {
  id = id.replace(/^#/g, '');
  let elem;

  try {
    elem = document.querySelector(`#${id}`);
  } catch (error) {
    return;
  }

  const offset = disableOffset ? 0 : 100;

  if (elem) {
    const top = window.scrollY + elem.getBoundingClientRect().top - offset;

    window.scrollTo({
      behavior,
      top,
    });
    // eslint-disable-next-line
    history.replaceState(undefined, '', `#${id}`);
  }
};

export default scrollToElementByID;
