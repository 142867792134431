// eslint-disable-next-line
export default {
  sendApplication: 'send-application-mail',
  getTariffs: 'get-tariffs',
  contactForm: 'submit-contact-form',
  loginSplitterProxy: 'login-splitter-proxy',
  submitSolarConfigurator: 'submit-solar-configurator',
  getSolarConfiguratorResultPdf: 'get-solar-configurator-result-pdf',
  getCities: 'get-cities',
  getStreetSuggestions: 'get-street-suggestions',
  getIbanValidations: 'get-iban-validations',
  submitEmobConfigurator: 'send-emobility-mail',
  submitSurfaceCheckConfigurator: 'send-surface-check-mails',
  getChangeoverDate: 'get-changeover-date',
  tariffs: 'tariffs',
  search: 'search',
  searchSuggestions: 'search/suggestions',
  jobAlertSubscribe: 'job-alert/subscribe',
  jobAlertUnsubscribe: 'job-alert/unsubscribe',
  disturbanceUnsubscribe: 'disturbance/unsubscribe',
  commandkLogin: 'commandk/login',
  uptimeRobotMonitors: 'uptime-monitors',
  sendReportFault: 'send-report-fault',
};

const allEnercityEndpoints = {
  prod: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `https://serviceportal.enercity.de/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `https://www.enercity.de`,
    assetsBaseUrl: `https://www.enercity.de`,
    loginSplitter: `https://loginsplitter.xtk-prod.de/v1`,
    enercityPortal: `https://account.enercity.de/enercity-account/login`,
    servicePortal: `https://serviceportal.enercity.de/action/login`,
    calorificValue: `/ws/calorificvalue`,
    formApi: 'https://form-api.aws-prod.enercity.de',
    reportFault:
      'https://www.enercity.de/ws/form/form/ea4ff7a0-a51f-4cb1-b5d5-b33ae8ca4ee0/record',
    disturbanceApi: 'https://db-rest.aws-prod.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-prod.enercity.de',
  },
  stage: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `http://serviceportal-i/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.stage-enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `https://enercity.stage.neoskop.cloud`,
    assetsBaseUrl: `https://enercity.stage.neoskop.cloud`,
    loginSplitter: `https://loginsplitter.xtk-stage.de/v1`,
    enercityPortal: `https://account.stage-enercity.de/enercity-account/login?showLogin=true`,
    servicePortal: `http://serviceportal-v/action/login`,
    calorificValue: `/ws/calorificvalue`,
    formApi: 'https://form-api.aws-stage.enercity.de',
    disturbanceApi: 'https://db-rest.aws-stage.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-stage.enercity.de',
  },
  dev: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `http://serviceportal-i/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.dev-enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `https://enercity.dev.neoskop.cloud`,
    assetsBaseUrl: `https://enercity.dev.neoskop.cloud`,
    loginSplitter: `https://loginsplitter.xtk-dev.de/v1`,
    enercityPortal: `https://account.dev-enercity.de/enercity-account/login?showLogin=true`,
    servicePortal: `http://serviceportal-v/action/login`,
    calorificValue: `/ws/calorificvalue`,
    formApi: 'https://form-api.aws-dev.enercity.de',
    disturbanceApi: 'https://db-rest.aws-dev.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-dev.enercity.de',
  },
  space: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `http://serviceportal-i/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.dev-enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `https://enercity.dev.neoskop.cloud`,
    assetsBaseUrl: `https://enercity.dev.neoskop.cloud`,
    loginSplitter: `https://loginsplitter.xtk-dev.de/v1`,
    enercityPortal: `http://serviceportal-v/action/login`,
    servicePortal: `https://account.dev-enercity.de/enercity-account/login?showLogin=true`,
    calorificValue: `/ws/calorificvalue`,
    formApi: 'https://form-api.aws-dev.enercity.de',
    disturbanceApi: 'https://db-rest.aws-dev.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-dev.enercity.de',
  },
  docker: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `https://serviceportal.xtk-dev.de/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.dev-enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `http://localhost:3000`,
    assetsBaseUrl: `http://localhost:3000`,
    loginSplitter: `https://loginsplitter.xtk-dev.de/v1`,
    enercityPortal: `http://serviceportal-v/action/login`,
    servicePortal: `https://account.dev-enercity.de/enercity-account/login?showLogin=true`,
    calorificValue: `https://www.enercity-netz.de/ws/calorificvalue`,
    formApi: 'https://form-api.aws-dev.enercity.de',
    disturbanceApi: 'https://db-rest.aws-dev.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-dev.enercity.de',
  },
  local: {
    zipcodeAutocomplete: `https://www.enercity.de/jaxrs/autocompletezipcity`,
    streetAutocomplete: `https://www.enercity.de/jaxrs/autocompletestreet`,
    createContract: `https://serviceportal.xtk-dev.de/action/createContractV2`,
    locationsJson: `/vorfahren-data/locations.json`,
    completionBaseUrl: `https://account.dev-enercity.de/enercity-account/vertragsabschluss/vertragsabschluss-starten`,
    baseUrl: `http://localhost:3000`,
    assetsBaseUrl: `http://localhost:3000`,
    loginSplitter: `https://loginsplitter.xtk-dev.de/v1`,
    enercityPortal: `http://serviceportal-v/action/login`,
    servicePortal: `https://account.dev-enercity.de/enercity-account/login?showLogin=true`,
    calorificValue: `https://www.enercity-netz.de/ws/calorificvalue`,
    formApi: 'https://form-api.aws-dev.enercity.de',
    disturbanceApi: 'https://db-rest.aws-dev.enercity.de/outage/v2',
    directusUrl: 'https://directus.aws-dev.enercity.de',
  },
} as const;

export const enercityEndpoints =
  allEnercityEndpoints[
    (process.env.STAGE || 'local') as keyof typeof allEnercityEndpoints
  ];
