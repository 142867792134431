import { AuthenticationAction, AuthenticationState } from './types';

export const authenticationReducer = (
  state: AuthenticationState,
  action: AuthenticationAction
) => {
  switch (action.type) {
    case 'SAVE_SESSION':
      return {
        ...state,
        user: action.payload,
        isAuthenticationModalOpen: false,
        modalError: undefined,
        modalReason: 'default' as const,
      };
    case 'SIGN_OUT':
      return {
        ...state,
        user: null,
      };
    case 'OPEN_AUTHENTICATION_MODAL':
      return {
        ...state,
        isAuthenticationModalOpen: true,
        modalReason: action.payload.modalReason ?? 'default',
        loginCallback: action.payload.loginCallback,
      };

    default:
      return state;
  }
};
