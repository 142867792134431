const isIE = (): boolean => {
  if (typeof window !== 'undefined') {
    return (
      window.navigator.userAgent.includes('MSIE ') ||
      !!navigator.userAgent.match(/Trident.*rv:11\./)
    );
  }
  return false;
};
export default isIE;
