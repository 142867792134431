import endpoints from './endpoints';

export type ProjectName =
  | 'enercity'
  | 'ecg'
  | 'eeg'
  | 'eng'
  | 'esg'
  | 'esol'
  | 'mein-h-gas';

export const environments = [
  'local',
  'docker',
  'dev',
  'stage',
  'prod',
] as const;

export type Environment = (typeof environments)[number];

export const currentStage = (process.env.STAGE || 'local') as Environment;

const isPreProduction = ['docker', 'local'].includes(currentStage);

export const currentProjectName = (process.env.PROJECT_NAME ||
  'enercity') as ProjectName;

export const ASSET_BASE_URL =
  process.env.ASSETS_PATH || 'http://localhost:3000';

export const IMAGE_BASE_URL = process.env.ASSETS_PATH;

const consentManagerId = process.env.CONSENT_MANAGER_ID;

export default {
  currentProjectName,
  isPreProduction,
  consentManagerId,
  ...{
    api: {
      baseUrl:
        (process.env.API_BASE_URL || 'http://localhost:3100/').replace(
          /\/$/,
          ''
        ) + '/',
    },
    googleKey: process.env.GOOGLE_API_KEY,
  },
} as const;

export { endpoints };
