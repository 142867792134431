const mailToParams = (
  cc?: string,
  bcc?: string,
  subject?: string,
  text?: string
): string => {
  if (!(cc || bcc || subject || text)) {
    return '';
  }

  const params = [];

  if (cc) {
    params.push(`cc=${cc}`);
  }

  if (bcc) {
    params.push(`bcc=${bcc}`);
  }

  if (subject) {
    params.push(`subject=${encodeURIComponent(subject)}`);
  }

  if (text) {
    params.push(`body=${encodeURIComponent(text)}`);
  }

  return `?${params.join('&')}`;
};

export default mailToParams;
