import {
  GoogleWindow,
  DataLayerEvent,
  UseDataLayer,
  PushDataLayerEventFn,
  PushVirtualPageViewFn,
  UpdateDataLayerByKeyFn,
} from './interfaces';
import { useCallback } from 'react';

const useDataLayer = (): UseDataLayer => {
  const pushDataLayerEvent: PushDataLayerEventFn = useCallback(
    (...events): void => {
      const _window = window as unknown as GoogleWindow;

      if (typeof window !== 'undefined') {
        _window.dataLayer = _window.dataLayer || [];
        const data = events.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            ...currentValue,
          }),
          {}
        );

        _window.dataLayer.push(data);
      }
    },
    []
  );

  const pushVirtualPageView: PushVirtualPageViewFn = useCallback(
    (virtualPath): void => {
      pushDataLayerEvent({
        event: 'Pageview',
        virtualPath,
      });
    },
    [pushDataLayerEvent]
  );

  const updateDataLayerByKey: UpdateDataLayerByKeyFn = useCallback(
    (key: string, updatedEvent: DataLayerEvent) => {
      const _window = window as unknown as GoogleWindow;

      if (typeof window !== 'undefined') {
        _window.dataLayer = _window.dataLayer || [];

        const oldValueIndex = _window.dataLayer.findIndex(
          (event) => key in event
        );

        _window.dataLayer[oldValueIndex] = updatedEvent;
      }
    },
    []
  );

  return {
    pushDataLayerEvent,
    pushVirtualPageView,
    updateDataLayerByKey,
  };
};

export default useDataLayer;
