import config, { IMAGE_BASE_URL } from '@/config';
import { logger } from '@/helpers/logger';
import type { AssetRect } from '@uikit/types';
import { createArrayNumbers } from '@uikit/utils/createArrayNumbers';
import { ImageTransformerParams } from './types';

const COMMON_IMAGE_TRANSFORMER_PARAMS: ImageTransformerParams = {
  resize: 'cover',
  q: 80,
};

const PLACEHOLDER_PARAMS: ImageTransformerParams = {
  blur: 10,
  q: 5,
  dpr: 1,
};

type WithBreakPoint = {
  breakpoint: number;
};

export const createSrcset = (
  path: string,
  { dpr = 1, ...params }: ImageTransformerParams
) => {
  return (createArrayNumbers(dpr) as ImageTransformerParams['dpr'][])
    .map(
      (dpr) =>
        `${createImageTransformerURL(path, {
          ...params,
          dpr,
        })} ${dpr}x`
    )
    .join(', ');
};

export const ratioVariantToRect = (ratioVariant?: AssetRect) => {
  return (
    ratioVariant &&
    [
      ratioVariant.x,
      ratioVariant.y,
      ratioVariant.width,
      ratioVariant.height,
    ].join(',')
  );
};

export const sortByBreakpoint = <
  T extends readonly WithBreakPoint[] | WithBreakPoint[],
>(
  array: T,
  sortOption?: 'desc' | 'asc'
) => {
  const clonedArray = [...array];
  return clonedArray.sort((a, b) =>
    sortOption === 'desc'
      ? b.breakpoint - a.breakpoint
      : a.breakpoint - b.breakpoint
  );
};

const isMagnoliaPath = (path: string) => path.startsWith('/dam');
const isAbsolutePath = (path: string) => path.startsWith('http');

export const createAbsolutePath = (path: string): string => {
  if (!path) {
    return '';
  }

  if (isAbsolutePath(path) || isMagnoliaPath(path)) {
    return path;
  }

  const url = config.isPreProduction
    ? `${config.api.baseUrl.replace(/\/$/, '')}`
    : `${IMAGE_BASE_URL}`;

  return `${url}/imgt/${path.replace(/^\//, '')}`;
};

export const createImageTransformerURL = (
  path: string,
  params: ImageTransformerParams = {}
): string => {
  if (!path) {
    logger.error('createImageTransformerURL: No path!!!');
    return path;
  }

  const url = new URL(createAbsolutePath(path));

  const _params = {
    ...COMMON_IMAGE_TRANSFORMER_PARAMS,
    ...params,
  };

  for (const [key, value] of Object.entries(_params)) {
    if (key && typeof value !== 'undefined') {
      url.searchParams.set(key, encodeURIComponent(value));
    }
  }

  return url.toString();
};

export const createImageURL = (
  path: string,
  params: ImageTransformerParams = {}
): string => {
  if (!path) {
    logger.error('createImageURL: No path!!!');
    return path;
  }

  if (path.startsWith('/dam')) {
    return path;
  }

  return createImageTransformerURL(path, {
    q: 45,
    ...params,
  });
};

export const createPlaceholderURL = (
  path: string,
  params: ImageTransformerParams
) => {
  return createImageTransformerURL(path, {
    ...params,
    ...PLACEHOLDER_PARAMS,
  });
};

export const calculateHeightByAspectRatio = (
  width?: number,
  aspectRatio?: string
) => {
  if (!width || !aspectRatio) {
    return;
  }

  const [arWidth, arHeight] = aspectRatio
    .replace(' ', '')
    .replace(':', '/')
    .split('/')
    .map(Number);

  return Math.ceil((width * arHeight) / arWidth);
};
