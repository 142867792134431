import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import useReducerWithLocalStorage from '@/hooks/useReducerWithLocalStorage/useReducerWithLocalStorage';
import { firstIfArray } from '@uikit/utils/firstIfArray';
import React from 'react';
import {
  UserDataAction,
  UserDataContextValue,
  UserDataProviderProps,
  UserDataState,
} from './interfaces';
import { userDataReducer } from './reducers';

export const UserDataContext = React.createContext<UserDataContextValue>({
  state: {},
  dispatch: () => void 0,
});

export const UserDataProvider = ({ children }: UserDataProviderProps) => {
  const {
    params: {
      streetname: streetName,
      streetnumber: streetNumber,
      zipcode: zipCode,
    },
  } = useQueryParams();

  const queryParams = {
    streetName: firstIfArray(streetName),
    streetNumber: firstIfArray(streetNumber),
    zipCode: firstIfArray(zipCode),
  };

  const [state, dispatch] = useReducerWithLocalStorage<
    UserDataState,
    UserDataAction
  >(userDataReducer, queryParams, {
    key: 'enercity@user-data',
    overwriteLocalStorage: true,
  });

  React.useEffect(() => {
    if (typeof queryParams.zipCode === 'string') {
      dispatch({ type: 'SET_ZIP_CODE', payload: queryParams.zipCode });
    }
  }, [dispatch, queryParams.zipCode]);

  return (
    <UserDataContext.Provider value={{ state, dispatch }}>
      {children}
    </UserDataContext.Provider>
  );
};
