import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import type { SliderProps } from './interfaces';
import styles from './Slider.module.scss';

const Slider = ({
  children,
  initial = 0,
  itemsPerSlide = 1,
  itemWidth,
  className,
  ...additionalProps
}: SliderProps) => {
  const [base, setBase] = useState<HTMLElement>();
  const [baseWidth, setBaseWidth] = useState(0);
  const [trackTransform, setTrackTransform] = useState(0);
  const [current, setCurrent] = useState(initial);

  const baseRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setBase(node);
    }
  }, []);

  if (typeof itemWidth === 'undefined') {
    itemWidth = baseWidth / itemsPerSlide - 8;
  } else {
    itemsPerSlide = base ? Math.floor(baseWidth / itemWidth) : 4;
  }

  const childrenArray = React.Children.toArray(children).filter(
    (child) => typeof child !== 'string'
  );

  const handleResize = useCallback(() => {
    setBaseWidth(base ? base.getBoundingClientRect().width : 0);
  }, [base]);

  const bind = useDrag(({ down, direction: [xDir], distance }) => {
    if (!down && distance > window.innerWidth / 8) {
      const offset =
        xDir > 0.9 && !(current <= 0)
          ? -1
          : xDir < -0.9 && !(current >= childrenArray.length - itemsPerSlide)
          ? 1
          : 0;
      setCurrent(current + offset);
    }
  });

  useEffect(() => {
    if (current >= childrenArray.length) {
      setCurrent(childrenArray.length - 1);
    }

    if (childrenArray.length <= itemsPerSlide) {
      setCurrent(0);
    }
  }, [current, childrenArray, itemsPerSlide]);

  useEffect(() => {
    setTrackTransform((itemWidth || 0) * current);
  }, [itemWidth, current]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();
      window.addEventListener('resize', handleResize, { passive: true });
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [handleResize]);

  return (
    <div
      className={clsx(styles.base, className)}
      ref={baseRef as React.Ref<HTMLDivElement>}
      {...additionalProps}
    >
      <div
        className={styles.track}
        style={{ transform: `translateX(-${trackTransform}px)` }}
        {...bind()}
      >
        {React.Children.map(children, (child, idx) =>
          typeof child !== 'string' ? (
            <div className={styles.child} key={idx}>
              {child}
            </div>
          ) : (
            child
          )
        )}
      </div>
    </div>
  );
};

export default Slider;
