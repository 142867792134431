import ModalContext from '@/contexts/Modal.context';
import clsx from 'clsx';
import { useContext } from 'react';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Button from '../Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  ShowIcon,
} from '../Button/consts';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import { ClickableProps } from '../Clickable/interfaces';
import GuideBoxOverlay from '../GuideBox/GuideBoxOverlay';
import Icon from '../Icon/Icon';
import RichText from '../RichText/RichText';
import type { TeaserTileProps } from './interfaces';
import styles from './TeaserTile.module.scss';

const TeaserTile = ({
  children,
  color = 'simple',
  icon,
  showIcon,
  buttonLabel = '',
  description,
  buttonIcon,
  params = '',
  className,
  guide,
  button,
  ...props
}: TeaserTileProps) => {
  const colorStyle = styles[color];
  const clickableProps = extractClickableProps(props);
  const classname = clsx(colorStyle, styles.base, className);
  const { closeModal, showModal } = useContext(ModalContext);

  const handleGuideClose = () => {
    closeModal();
  };

  const handleGuideOpen = () => {
    showModal(`guide-${guide?.name}`);
  };

  const guideClickableProps: ClickableProps = {
    actionType: ClickableAction.Custom,
    onClick: handleGuideOpen,
  };

  const currentClickableProps =
    button === 'guide' ? guideClickableProps : clickableProps;

  return (
    <>
      {guide && (
        <GuideBoxOverlay
          coloured={color !== 'simple'}
          close={handleGuideClose}
          {...guide}
        />
      )}
      <Clickable
        className={classname}
        {...currentClickableProps}
        clickContext={children?.toString()}
      >
        {showIcon && icon && (
          <Icon variant={icon} size="iconSize32" className={styles.icon} />
        )}
        {children && <div className={styles.title}>{children}</div>}
        {description && (
          <RichText className={styles.description}>{description}</RichText>
        )}
        <Button
          size={ButtonSize.Medium}
          color={
            color === 'simple' ? ButtonColor.Primary : ButtonColor.Secondary
          }
          actionType={ClickableAction.None}
          width={ButtonWidth.Full}
          align={ButtonAlign.Center}
          icon={buttonIcon}
          showIcon={buttonIcon ? ShowIcon.ShowIcon : ShowIcon.None}
          noClickable
          asDiv
        >
          {buttonLabel}
        </Button>
      </Clickable>
    </>
  );
};

export default TeaserTile;
