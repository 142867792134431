import React from 'react';
import type { GlobalAuthenticationModalProps } from './interfaces';
import { BoxWidth } from '../Box/consts';
import ModalContent from '../Modal/ModalContent';
import LoginForm from './forms/LoginForm/LoginForm';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import ModalCloseIcon from '../Modal/ModalCloseIcon/ModalCloseIcon';

const GlobalAuthenticationModal = ({
  isOpen,
  onClose,
  handleLogin,
  errorMessage,
}: GlobalAuthenticationModalProps) => {
  return (
    <ModalPortal
      onClose={onClose}
      variant="shade"
      id="global-authentication-modal"
      show={isOpen}
      disableCloseIcon={true}
    >
      <ModalContent width={BoxWidth.Medium}>
        <ModalCloseIcon onClick={onClose} />
        <LoginForm callbackOnSubmit={handleLogin} errorMessage={errorMessage} />
      </ModalContent>
    </ModalPortal>
  );
};

export default GlobalAuthenticationModal;
