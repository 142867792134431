import { logger } from '@/helpers/logger';
import { Hotkey } from '@/hooks/useHotkey/types';
import { createContext, useCallback, useEffect, useRef } from 'react';
import { getStrokesFromKeyboardEvent } from './helpers';
import { HotkeyContextProviderProps, HotkeyContextValue } from './types';

const initialValue: HotkeyContextValue = {
  hotkeys: [],
  registerHotkey: () => void 0,
  unregisterHotkey: () => void 0,
};

const HotkeyContext = createContext<HotkeyContextValue>(initialValue);

export const HotkeyProvider = ({ children }: HotkeyContextProviderProps) => {
  const activeChainTrigger = useRef<string>();
  const chainTimeout = useRef<number>();
  const chainTriggers = useRef<string[]>([]);
  const hotkeys = useRef<Hotkey[]>([]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (
      !event.key ||
      ['alt', 'control', 'meta', 'shift'].includes(event.key.toLowerCase())
    ) {
      return;
    }

    const currentStrokes = getStrokesFromKeyboardEvent(event);

    if (!!activeChainTrigger.current) {
      event.preventDefault();
      event.stopPropagation();

      const matching = hotkeys.current
        .filter(
          ({ chainedWith, strokes }) =>
            !!chainedWith &&
            chainedWith === activeChainTrigger.current &&
            strokes === currentStrokes
        )
        .at(-1);

      if (matching) {
        matching.callback();
      }

      clearTimeout(chainTimeout.current);
      activeChainTrigger.current = undefined;
      chainTimeout.current = undefined;
      return;
    }

    const matching = hotkeys.current
      .filter(
        ({ chainedWith, strokes }) => !chainedWith && strokes === currentStrokes
      )
      .at(-1);

    if (matching) {
      event.preventDefault();
      event.stopPropagation();

      matching.callback();
      return;
    }

    if (chainTriggers.current.includes(currentStrokes)) {
      event.preventDefault();
      event.stopPropagation();

      activeChainTrigger.current = currentStrokes;
      chainTimeout.current = window.setTimeout(
        () => (activeChainTrigger.current = undefined),
        5000
      );
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const registerHotkey = (hotkey: Hotkey) => {
    if (
      !!hotkey.chainedWith &&
      !chainTriggers.current.includes(hotkey.chainedWith)
    ) {
      const shadowedBy = hotkeys.current
        .filter((h) => h.strokes === hotkey.chainedWith)
        .at(-1);

      if (shadowedBy) {
        logger.warn(
          `Der Hotkey ${hotkey.chainedWith} ${
            hotkey.strokes
          } kann nicht ausgelöst werden, da er durch den bestehenden Hotkey "${
            shadowedBy.description ?? shadowedBy.strokes
          }" (${shadowedBy.scope ?? 'Kein Scope'}) blockiert wird.`
        );
      }

      chainTriggers.current = [...chainTriggers.current, hotkey.chainedWith];
    } else if (
      !hotkey.chainedWith &&
      chainTriggers.current.includes(hotkey.strokes)
    ) {
      const shadowing = hotkeys.current.filter(
        (h) => h.chainedWith === hotkey.strokes
      );

      if (shadowing.length > 0) {
        logger.warn(
          `Der Hotkey ${
            hotkey.strokes
          } blockiert bestehende Hotkeys.\n\n${shadowing
            .map(
              (h) =>
                `- ${h.chainedWith} ${h.strokes}: "${
                  h.description ?? 'Keine Beschreibung'
                }" (${h.scope ?? 'Kein Scope'})`
            )
            .join('\n')}`
        );
      }
    }

    hotkeys.current = [...hotkeys.current, hotkey];
  };

  const unregisterHotkey = (hotkey: Hotkey) => {
    hotkeys.current = hotkeys.current.filter((h) => h.id !== hotkey.id);

    if (
      !!hotkey.chainedWith &&
      hotkeys.current.every((h) => h.chainedWith !== hotkey.chainedWith)
    ) {
      chainTriggers.current = chainTriggers.current.filter(
        (trigger) => trigger !== hotkey.chainedWith
      );
    }
  };

  const value: HotkeyContextValue = {
    hotkeys: hotkeys.current,
    registerHotkey,
    unregisterHotkey,
  };

  return (
    <HotkeyContext.Provider value={value}>{children}</HotkeyContext.Provider>
  );
};

export default HotkeyContext;
