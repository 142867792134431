import usePathname from '@/hooks/usePathname/usePathname';
import stripHTMLTags from '@uikit/helpers/stripHTMLTags';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import useDataLayer from '../../../hooks/useDataLayer/useDataLayer';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import Icon from '../Icon/Icon';
import styles from './Button.module.scss';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonWidth,
  ShowAddon,
  ShowIcon,
} from './consts';
import type { ButtonProps } from './interfaces';

const Button = React.forwardRef(
  (props: ButtonProps, ref?: React.Ref<any>): JSX.Element => {
    const {
      align = ButtonAlign.Left,
      animate = true,
      children,
      className: _className,
      color = ButtonColor.Primary,
      customAddonClass,
      customDataLayer,
      icon,
      inactive = false,
      noClickable,
      onBlur,
      showAddon = ShowAddon.None,
      showIcon = ShowIcon.None,
      size = ButtonSize.Medium,
      width = ButtonWidth.Auto,

      ...additionalProps
    } = props;

    const { pushDataLayerEvent } = useDataLayer();
    const pathname = usePathname();

    const clickableProps = extractClickableProps(props);

    const pushCustomClickEvent = useCallback(
      ($event: React.SyntheticEvent<HTMLElement>) => {
        pushDataLayerEvent({
          event: 'customClick',
          clickContext: props.clickContext
            ? stripHTMLTags(props.clickContext)
            : 'undefined',
          customClickText: $event.currentTarget.innerText.replace(/\n.*/gm, ''),
          customPagePath: pathname.split('?')[0],
          ...props.customDataLayer,
        });
      },
      [pathname, props.clickContext, props.customDataLayer, pushDataLayerEvent]
    );

    const handleOnClick = useCallback(
      ($event: React.MouseEvent<HTMLElement>) => {
        if (props.withoutDataLayer !== true) {
          pushCustomClickEvent($event);
        }

        additionalProps.onClick && additionalProps.onClick($event);
      },
      [props.withoutDataLayer, additionalProps, pushCustomClickEvent]
    );

    const addon = showAddon !== ShowAddon.None;

    const baseClasses = clsx(
      styles[color],
      styles[size],
      styles[align],
      styles[width],
      styles.default,
      {
        [styles.inactive]: inactive,
        [styles.noHover]: !animate,
        [styles.addon]:
          showAddon !== ShowAddon.None && showIcon !== ShowIcon.None,
      },
      _className
    );

    const addonWrapper = clsx(
      styles.addonWrapper,
      {
        [styles.gradient]: showAddon === ShowAddon.Gradient,
        [styles.gradientMeinHGas]: showAddon === ShowAddon.GradientMeinHGas,
        [styles.plain]: showAddon === ShowAddon.Plain,
      },
      customAddonClass
    );

    const innerHtml = (
      <>
        {showIcon === ShowIcon.ShowIcon && icon && (
          <>
            {!addon && (
              <Icon
                size="iconSize32"
                className={styles.icon}
                variant={
                  clickableProps.actionType === ClickableAction.Lightbox &&
                  clickableProps.videolink
                    ? 'user/play'
                    : icon
                }
              />
            )}
            {addon && (
              <span className={styles.addonContainer}>
                <span className={addonWrapper}>
                  <Icon
                    variant={
                      clickableProps.actionType === ClickableAction.Lightbox &&
                      clickableProps.videolink
                        ? 'user/play'
                        : icon
                    }
                    size="iconSize24"
                  />
                </span>
              </span>
            )}
          </>
        )}

        <span className={styles.text}>{children}</span>
      </>
    );

    return noClickable || additionalProps.type === ButtonType.Submit ? (
      clickableProps.asDiv ? (
        <div
          ref={ref}
          title={additionalProps.title}
          className={clsx(baseClasses, [styles.noHover])}
        >
          {innerHtml}
        </div>
      ) : (
        <button
          ref={ref}
          title={additionalProps.title}
          className={baseClasses}
          onClick={handleOnClick}
          onBlur={onBlur}
          type={additionalProps.type}
          id={additionalProps.id}
          tabIndex={additionalProps.tabIndex}
          aria-controls={additionalProps['aria-controls']}
          aria-expanded={additionalProps['aria-expanded']}
        >
          {innerHtml}
        </button>
      )
    ) : (
      <Clickable
        {...clickableProps}
        {...additionalProps}
        className={baseClasses}
      >
        {innerHtml}
      </Clickable>
    );
  }
);

Button.displayName = 'Button';

export default Button;
