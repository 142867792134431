import xss from 'xss';

const allowedClasses: Record<string, string[]> = {
  strong: ['strong-surrogate'],
};

const allowedStyleProps: string[] = ['text-align'];

export const getSafeInnerHTML = (content: string) => {
  return xss(content, {
    onTagAttr: (tag, attr, value) => {
      if (attr !== 'class' || !allowedClasses[tag]) {
        return undefined;
      }

      const classesSet = value.split(' ');
      return `class="${allowedClasses[tag]
        .filter((className) => classesSet.includes(className))
        .join(' ')}"`;
    },
    onIgnoreTagAttr: (_tag, attr, value) => {
      if (attr === 'style') {
        const styles = value.split(/;\s*/);
        const allowedStyles = styles.filter((style) => {
          const prop = style.split(/:\s*/)[0];
          return allowedStyleProps.includes(prop);
        });
        return `style="${allowedStyles.join(';')}"`;
      }
    },
  });
};

export const getSafeInnerHTMLProp = (content: string) => ({
  dangerouslySetInnerHTML: {
    __html: getSafeInnerHTML(content),
  },
});
