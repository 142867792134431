type ToIDOptions = {
  maxLength?: number;
  replaceSpacesWith?: string;
};

const toID = (value: string | number, options?: ToIDOptions): string => {
  const id = `${value}`
    .toLowerCase()
    .replace(/\s/g, options?.replaceSpacesWith ?? '_')
    .replace(/[^_\-a-zA-Z0-9]/g, '');

  return options?.maxLength === -1 ? id : id.slice(0, options?.maxLength ?? 15);
};

export default toID;
