import { logger } from '@/helpers/logger';
import useBenefits from '@/hooks/useBenefits/useBenefits';
import { LoginCredentials } from '@/hooks/useLogin/types';
import { useLogin } from '@/hooks/useLogin/useLogin';
import { useLogout } from '@/hooks/useLogout/useLogout';
import GlobalAuthenticationModal from '@uikit/components/GlobalAuthenticationModal/GlobalAuthenticationModal';
import React, { useCallback, useEffect } from 'react';
import { isSessionExpired } from './helpers';
import {
  AuthenticationAction,
  AuthenticationContextValue,
  AuthenticationState,
  OpenAuthenticationModalProps,
  SaveSessionProps,
} from './types';

const initialValue: AuthenticationContextValue = {
  state: {
    user: null,
    loginCallback: undefined,
    modalReason: 'default',
  },
  openAuthenticationModal: () => void 0,
  closeAuthenticationModal: () => void 0,
  saveSession: () => void 0,
  signOut: async () => void 0,
};

export const AuthenticationContext =
  React.createContext<AuthenticationContextValue>(initialValue);

export const AuthenticationProvider = ({
  state,
  dispatch,
  children,
}: {
  state: AuthenticationState;
  dispatch: React.Dispatch<AuthenticationAction>;
  children: React.ReactNode;
}) => {
  const { resetBenefits } = useBenefits();
  const login = useLogin();
  const logout = useLogout();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] =
    React.useState(false);

  const handleLogin = async ({ email, password }: LoginCredentials) => {
    try {
      const loginResponse = await login({ email, password });

      saveSession(loginResponse.requestedData);
      setIsAuthenticationModalOpen(false);
    } catch (error) {
      let message: string | undefined;

      // todo: add error instance of LambdaErrorResponse
      if (error && typeof error === 'object') {
        message = (error as any).errorMessage;
      }

      if (error instanceof Error) {
        logger.error(error);
      }

      setErrorMessage(
        message ??
          'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.'
      );

      throw new Error("Couldn't login");
    }
  };

  const openAuthenticationModal = (props: OpenAuthenticationModalProps) => {
    dispatch({
      type: 'OPEN_AUTHENTICATION_MODAL',
      payload: props,
    });
    setIsAuthenticationModalOpen(true);
  };

  const closeAuthenticationModal = () => {
    setIsAuthenticationModalOpen(false);
    setErrorMessage(undefined);
  };

  const signOut = useCallback(async () => {
    try {
      await logout({ ed4Token: state.user?.ed4?.token });
    } catch (error) {
      logger.log("Can't sign out because of error in lambda");
    }

    dispatch({ type: 'SIGN_OUT' });
    resetBenefits();
  }, [dispatch, logout, resetBenefits, state.user?.ed4?.token]);

  const saveSession = (props: SaveSessionProps) => {
    dispatch({
      type: 'SAVE_SESSION',
      payload: props,
    });
  };

  useEffect(() => {
    if (state.user) {
      if (
        isSessionExpired(state.user.ed4) ||
        isSessionExpired(state.user.isu)
      ) {
        signOut();
      }
    }
  }, [signOut, state.user]);

  return (
    <AuthenticationContext.Provider
      value={{
        state,
        openAuthenticationModal,
        closeAuthenticationModal,
        signOut,
        saveSession,
      }}
    >
      {children}
      <GlobalAuthenticationModal
        isOpen={isAuthenticationModalOpen}
        onClose={closeAuthenticationModal}
        handleLogin={handleLogin}
        errorMessage={errorMessage}
      />
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
