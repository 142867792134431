import { useEffect, useState } from 'react';
import { UseScriptConfigVariant, useScriptConfigs } from './scriptVariants';

type UseScriptConfig = {
  removeOnUnmount?: boolean;
};

export enum Status {
  idle = 'idle',
  loading = 'loading',
  ready = 'ready',
  error = 'error',
}

const useScript = (
  configVariant?: UseScriptConfigVariant,
  { removeOnUnmount }: UseScriptConfig = {}
) => {
  const { src } = configVariant
    ? useScriptConfigs[configVariant]
    : { src: undefined };
  const [status, setStatus] = useState<Status>(
    src ? Status.loading : Status.idle
  );

  useEffect(() => {
    if (!src) {
      return;
    }
    // Get existing script element by src
    // It may have been added by another instance of this hook
    let script = document.querySelector(
      `script[src="${src}"]`
    ) as HTMLScriptElement;

    const setAttributeFromEvent = (event: Event) => {
      script.setAttribute(
        'data-status',
        event.type === 'load' ? Status.ready : Status.error
      );
    };

    if (script) {
      // Get existing script status from attribute and set to state.
      setStatus(script.getAttribute('data-status') as Status);
    } else {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', Status.loading);
      document.body.append(script);
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? Status.ready : Status.error);
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (!script) {
        return;
      }

      script.removeEventListener('load', setStateFromEvent);
      script.removeEventListener('error', setStateFromEvent);
      script.removeEventListener('load', setAttributeFromEvent);
      script.removeEventListener('error', setAttributeFromEvent);

      if (removeOnUnmount) {
        script.remove();
      }
    };
  }, [removeOnUnmount, src]);

  return status;
};

export default useScript;
