import { LoginPlatformResponse } from '@/hooks/useLogin/types';
import { AuthenticationAction, AuthenticationState } from './types';

export const signInMiddleware = async (
  state: AuthenticationState,
  action: AuthenticationAction
) => {
  if (action.type === 'SAVE_SESSION' && state.user && !!state.loginCallback) {
    await state.loginCallback?.();

    return {
      ...state,
      loginCallback: undefined,
    };
  }
};

export const isSessionExpired = (session?: LoginPlatformResponse) => {
  if (!session) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  return session.exp < currentTime;
};
